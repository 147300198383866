import React, { useState } from "react";
import CustomH2Heading from "../../components/CustomH2Heading";
import { images } from "../../utils/images";
import CustomResponsiveImage from "../../components/CustomResponsiveImage";

const SwitchesModule = () => {
  const [selectedButton, setSelectedButton] = useState(0);

  let allButtons = [
    "2 MODULE",
    "4 MODULE",
    "6 MODULE",
    "8 MODULE",
    "12 MODULE",
    // "18 MODULE"
  ];

  return (
    <div>
      <div className="switch-container">
        <div className="switches-heading">
          <CustomH2Heading
            title="DIVERSE RANGES OF SIZES"
            isUpperCase={true}
            customClass="product-header"
          />
        </div>
        <div className="switches-description">
          Explore the variety of Technixia Smart Touch Switches & discover the
          perfect fit for your wall
        </div>
      </div>
      <div className="switches-button-container">
        {allButtons?.map((btn, index) => {
          return (
            <div
              className="switches-custom-button"
              name={btn}
              onClick={() => {
                setSelectedButton(index);
              }}
              style={{
                backgroundColor: selectedButton === index ? "#fff" : "#04592b",
                color: selectedButton === index ? "#000" : "white",
                border:
                  selectedButton === index
                    ? "1px solid #04592b"
                    : "1px solid #416082",
              }}
            >
              {btn}
            </div>
          );
        })}
      </div>

      {selectedButton === 0 && (
        <div className="switches-image-container">
          <CustomResponsiveImage
            imageArr={[
              {
                image: images.MODULE_SWITCHES_2_MOBILE,
                maxWidth: 500,
              },
              {
                image: images.MODULE_SWITCHES_2,
              },
            ]}
            alt="switches products"
            className="switches-image-container"
          />
        </div>
      )}
      {selectedButton === 1 && (
        <div className="switches-image-container">
          <CustomResponsiveImage
            imageArr={[
              {
                image: images.MODULE_SWITCHES_4_MOBILE,
                maxWidth: 500,
              },
              {
                image: images.MODULE_SWITCHES_4,
              },
            ]}
            alt="switches products"
            className="switches-image-container"
          />
        </div>
      )}
      {selectedButton === 2 && (
        <CustomResponsiveImage
          imageArr={[
            {
              image: images.MODULE_SWITCHES_6_MOBILE,
              maxWidth: 500,
            },
            {
              image: images.MODULE_SWITCHES_6,
            },
          ]}
          alt="switches products"
          className="switches-image-container"
        />
      )}
      {selectedButton === 3 && (
        <CustomResponsiveImage
          imageArr={[
            {
              image: images.MODULE_SWITCHES_8_MOBILE,
              maxWidth: 500,
            },
            {
              image: images.MODULE_SWITCHES_8,
            },
          ]}
          alt="switches products"
          className="switches-image-container"
        />
      )}
      {selectedButton === 4 && (
        <CustomResponsiveImage
          imageArr={[
            {
              image: images.MODULE_SWITCHES_12_MOBILE,
              maxWidth: 500,
            },
            {
              image: images.MODULE_SWITCHES_12,
            },
          ]}
          alt="switches products"
          className="switches-image-container"
        />
      )}
    </div>
  );
};

export default SwitchesModule;
