import React from "react";
import Slider from "react-slick";

const CourasalComponent = ({ list, itemComponent, additionalOptions = {} }) => {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: false,
    slidesToShow: 5,
    initialSlide: 3,
    speed: 1000,
    slidesToScroll: 1,
    autoplay: false,
    swipeToSlide: true,
    arrows: false,
    dots: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    ...additionalOptions,
  };

  return (
    <div>
      <Slider {...settings}>
        {list?.map((item, i) => {
          return itemComponent(item, i);
        })}
      </Slider>
    </div>
  );
};

export default CourasalComponent;
