import React from "react";

const OverlayImageComponent = ({ image, className = "", alt = "product" }) => {
  return (
    <li className="image_wrapper">
      <img alt={alt} src={image} className={className} />
      <div className="overlay overlay_0"></div>
    </li>
  );
};

export default OverlayImageComponent;
