import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import CustomH2Heading from "../../components/CustomH2Heading";
import ProductDisplay from "./ProductDisplay"; 
import TrendingSale from "./TrendingSale";
import BestSeller from "./BestSeller";
import AboutProduct from "./AboutProduct";
import TestiMonial from "../../components/Testimonial/TestimonialGlobal";
import ApiCall from "../../api/ApiCall";
import { productRoutes } from "../../api/Routes";
import CustomResponsiveImage from "../../components/CustomResponsiveImage";
import Loader from "../../components/Loader";
import Footer from "../../components/Footer";
import SwitchesModule from "./SwitchesModule";
import CustomizationsSection from "../Home/SubItems/CustomizationsSection";
import ProductsWarranty from "./ProductsWarranty";
import BookDemoModal from "../../components/BookDemoModal";
import GetQuoteModal from "../../components/GetQuoteModal";
import { getBannerData } from "../../globalFunctions/globalFunctions";
import { bannerKeys } from "../../utils/constants";
import { images } from "../../utils/images";

const SmartTouchSwitchesCategory = () => {
  const categoryId = "6576afdf392732f986eba22c";
  const [showLoader, setShowLoader] = useState(true);
  const [showModalFreeDemo, setShowModalFreeDemo] = useState(false);
  const [showModalGetQuote, setShowModalGetQuote] = useState(false);
  const [allBanner, setAllBanner] = useState(null);

  const [bestSellerProducts, setBestSellerProducts] = useState(null);
  const [switchesProductsSale, setSwitchesProductsSale] = useState(null);
  const [allSubcategories, setAllSubcategories] = useState(null);

  const getBestSellerProducts = async () => {
    const data = {
      productCategoryId: categoryId,
      isBestSeller: true,
      pageNumber: 1,
      pageLimit: 4,
    };
    const res = await ApiCall("post", productRoutes.getProducts, data);
    setBestSellerProducts(res?.product?.data);
  };

  const getSaleProducts = async () => {
    const data = {
      productCategoryId: categoryId,
      isLightningOffer: true,
      pageNumber: 1,
      pageLimit: 4,
    };
    const res = await ApiCall("post", productRoutes.getProducts, data);
    setSwitchesProductsSale(res?.product?.data);
  };

  const handleGetproductSubCategories = async () => {
    const data = {
      productCategoryId: categoryId,
    };
    const res = await ApiCall(
      "post",
      productRoutes.getProductSubCategory,
      data
    );
    setAllSubcategories(res?.productSubCategory);
  };

  useEffect(() => {
    handleGetproductSubCategories();
    getBestSellerProducts();
    getSaleProducts();
    getBannerData(bannerKeys.SMART_SWITCHES, setAllBanner);
  }, []);

  useEffect(() => {
    if (
      bestSellerProducts !== null &&
      switchesProductsSale !== null &&
      allSubcategories !== null &&
      allBanner !== null
    ) {
      setShowLoader(false);
    }
  }, [bestSellerProducts, switchesProductsSale, allSubcategories, allBanner]);

  const mainBanner = allBanner?.[0];
  const imagesArr = mainBanner?.backgroundImg;

  return showLoader ? (
    <Loader />
  ) : (
    <div>
      <section>
        <Header />
      </section>
      <div className="product-category-header">
        <div className="product-category-banner">
          <CustomResponsiveImage
            imageArr={imagesArr}
            className="prod-banner"
          />
        </div>
      </div>
      <div className="product-container">
        <div className="product-card-header-new">
          <CustomH2Heading
            title="Smart Touch Switches"
            isUpperCase={true}
            customClass="product-header"
          />
        </div>
        <p className="product-product-description">
          Enter a different dimension as you walk into your home.
        </p>
      </div>
      <ProductDisplay allSubcategories={allSubcategories} />
      {bestSellerProducts && bestSellerProducts.length > 0 && (
        <BestSeller allProducts={bestSellerProducts} />
      )}
      {switchesProductsSale && switchesProductsSale.length > 0 && (
        <TrendingSale allProducts={switchesProductsSale} />
      )}
      <AboutProduct
        img={images.SMART_SWITCHES}
        description={
          "Technixia Smart touch switches or touch-sensitive smart switches, are advanced electrical switches designed to control various electronic devices or lighting fixtures in a home or office environment. Unlike traditional switches that rely on physical toggling, smart touch switches utilize touch-sensitive technology for operation. Technixia Smart touch switches have a touch-sensitive surface that responds to human touch, eliminating the need for physical buttons or switches. A simple tap or swipe gesture on the touch panel is all it takes to control the connected devices These switches are equipped with wireless connectivity capabilities, such as Wi-Fi or Bluetooth, enabling them to connect to other smart devices or a central smart home hub. This connectivity allows users to control the switches remotely via smartphone apps or voice commands through virtual assistants like Amazon Alexa or Google Assistant."
        }
        onButtonPress={() => setShowModalFreeDemo(true)}
      />
      <SwitchesModule />
      <CustomizationsSection onButtonClick={() => setShowModalGetQuote(true)} />
      <ProductsWarranty />
      <TestiMonial />
      <BookDemoModal
        setShowModal={setShowModalFreeDemo}
        showModal={showModalFreeDemo}
      />
      <GetQuoteModal
        setShowModal={setShowModalGetQuote}
        showModal={showModalGetQuote}
      />
      <Footer />
    </div>
  );
};

export default SmartTouchSwitchesCategory;
