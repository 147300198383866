import React from "react";
import { recognisedByArr } from "../pages/AboutUs/AboutContent";
import CustomH2Heading from "./CustomH2Heading";

const RecognisedBy = ({ text, className = "" }) => {
  return (
    <div className={`recogonised-by ${className}`}>
      <div className="recognised-by-header">
        <CustomH2Heading
          customClass="recognised-by-header-heading"
          title={text}
        />
      </div>
      <div className="recognised-by-data">
        <div className="recognised-by-items">
          {recognisedByArr?.map((item) => {
            return (
              <div className="recognised-item">
                <img
                  className="recognised-by-image"
                  src={item.image}
                  alt="recognised-by"
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RecognisedBy;
