import React, { useState } from "react";
import ApiCall from "../api/ApiCall";
import { customerRoutes } from "../api/Routes";
import CustomModal from "./CustomModal";
import { analyticsInstance } from "../globalFunctions/BaseTracking";
import constants from "../utils/constants";
import { isEmpty } from "lodash";

const GetQuoteModal = ({ showModal, setShowModal, productDetails = {} }) => {
  const [name, setname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [productInfo, setproductInfo] = useState("");

  const emailValidation =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const handleReset = () => {
    setShowModal(false);
    setname("");
    setPhoneNumber("");
    setEmail("");
    setMessage("");
    setproductInfo("")
  };

  const handleAddContact = async (e) => {
    e.preventDefault();
    const data = {
      customerName: name,
      customerEmail: email,
      customerPhone: phoneNumber,
      productName: isEmpty(productDetails) ? productInfo : productDetails?.name,
      requestMessage: message,
    };
    const res = await ApiCall("post", customerRoutes.addCustomerRequest, data);

    if (res?.success) {
      const productData = {
        productId: productDetails?._id,
        name: isEmpty(productDetails) ? productInfo : productDetails?.name,
        offer: productDetails?.offer,
        itemCode: productDetails?.itemCode,
        productCategoryId: productDetails?.category,
        productSubCategoryId: productDetails?.subCategory,
        customerName: name,
        customerEmail: email,
        customerPhone: phoneNumber,
        requestMessage: message,
      };
      analyticsInstance().event({
        category: constants.PRODUCT,
        action: "product_quote_request",
        label: "Product Quote Request",
        options: productData,
      });

      handleReset();
      alert(
        "Request has been send Successfully , our team will reach you very soon"
      );
    }
  };

  const getQuoteForm = () => {
    return (
      <div className="contact-form-w-100 w-lg-50">
        <div className="contact-inner-column1">
          <div className="contact-getquote-form contact-form-getquote">
            <form onSubmit={handleAddContact}>
              <div className="row mid-spacing">
                <div className="form-group col-lg-12 col-md-12 col-sm-12">
                  <label> Product Name*</label>
                  <input
                    type="text"
                    name="productname"
                    className="productname"
                    placeholder="Enter Interested Product Name"
                    onChange={(event) => setproductInfo(event.target.value)}
                    value={
                      isEmpty(productDetails)
                        ? productInfo
                        : productDetails?.name
                    }
                    disabled={isEmpty(productDetails) ? false : true}
                    style={{
                      backgroundColor: isEmpty(productDetails)
                        ? "#fff"
                        : "#efefef",
                    }}
                  />
                </div>

                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <label>Name*</label>
                  <input
                    type="text"
                    required
                    name="username"
                    className="username"
                    placeholder="Enter Your Name"
                    value={name}
                    onChange={(event) => setname(event.target.value)}
                  />
                </div>

                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <label>Email*</label>
                  <input
                    type="email"
                    required
                    name="email"
                    className="email"
                    placeholder="Enter Your Email"
                    pattern={emailValidation}
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </div>

                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <label>Phone *</label>
                  <input
                    type="text"
                    name="phone"
                    inputmode="numeric"
                    required
                    placeholder="Enter Phone Number"
                    value={phoneNumber}
                    maxlength="10"
                    minLength="10"
                    onChange={(e) => {
                      if (/^[0-9]*$/.test(e.target.value)) {
                        setPhoneNumber(e.target.value);
                      }
                    }}
                  />
                </div>

                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <label>City</label>
                  <input
                    type="text"
                    name="city"
                    className="city"
                    placeholder="Enter Your City"
                  />
                </div>

                <div className="form-group col-lg-12 col-md-12 col-sm-12">
                  <label>Message</label>
                  <textarea
                    name="contact_message"
                    placeholder="Enter Your Message"
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}
                  ></textarea>
                </div>
                <div className="add-to-cart-button-container">
                  <button
                    type="submit"
                    className="add-to-cart-button-new custom-button"
                  >
                    Get Quote
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <CustomModal
        showModal={showModal}
        innerComponent={getQuoteForm}
        text="Get Quote"
        customClass="popup-header"
        onCancelPress={handleReset}
      />
    </div>
  );
};

export default GetQuoteModal;
