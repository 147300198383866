import React from "react";
import { images } from "../../../utils/images";
import CustomizationCourasal from "../Coursal/CustomizationCourasal";
import CustomButton from "../../../components/CustomButton";

const CustomizationsSection = ({onButtonClick}) => {
  return (
    <section
      className="customization-section w-100 "
      style={{
        backgroundImage: `url(${images.CUSTOMIZATION_BACKGROUND})`,
        backgroundSize: "cover",
      }}
    >
      <div className="customization-heading">
        <h3 className="customization-title">Customization</h3>
        <div className="customization-text">
          Experience the ultimate in convenience and sophistication with our
          customizable products, Elevate your living space with a personalized
          touch and enhances both functionality and aesthetics.
        </div>
      </div>
      <CustomizationCourasal />
      <div className="w-100 customization-buttons d-flex flex-wrap align-items-sm-center  justify-content-evenly">
        {/* <div style={{ marginTop: 5, marginBottom: 5 }}>
          <CustomButton customStyle={{ width: 250 }} label={"Build your switchboard"} />
        </div> */}
        <div style={{ marginTop: 5, marginBottom: 5 }}>
          <CustomButton customStyle={{ width: 250 }} label={"Request quote"} onPress={onButtonClick} />
        </div>
      </div>
    </section>
  );
};

export default CustomizationsSection;
