import React from "react";
import ProductCard from "./ProductCard";

const AllProducts = ({ allSensorProducts }) => {
  return (
    <div className="all-products">
      <div className="all-products-details">
        <div className="all-products-w-100 d-flex all-products-flex justify-content-start">
          {allSensorProducts?.map((item) => {
            return <ProductCard item={item} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default AllProducts;
