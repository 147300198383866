import React, { useEffect } from "react";

const CustomModal = ({
  text,
  showModal,
  innerComponent,
  customClass = "",
  className = "",
  onCancelPress,
}) => {
  useEffect(() => {
    if (showModal) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [showModal]);

  return (
    <>
      <div className={showModal ? "blur-background" : ""}>
        {showModal && (
          <div className={`popup ${className}`}>
            <div className={`popup-heading ${customClass}`}>{text}</div>
            <div class="cancel-icon" onClick={onCancelPress}>
              <i class="fa fa-times" aria-hidden="true"></i>
            </div>
            {innerComponent && innerComponent()}
          </div>
        )}
      </div>
    </>
  );
};

export default CustomModal;
