import React, { useState } from "react";
import { Link } from "react-router-dom";
import SubNavItem from "./SubNavItem";
import { find } from "lodash";
import { MainNavRoutes } from "../RouteUtils";

const NavItem = ({ routeType, subMenu, isMobile, isDarkTheme, isSticky }) => {
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const navItem = find(MainNavRoutes, (item) => {
    if (routeType === item.to) {
      return true;
    }
  });
  const { label, to } = navItem || {};

  return (
    <li className={`dropdown ${isMobile && isDropdownActive ? "active" : ""}`}>
      <Link to={to}>
        <span
          style={{
            color: isSticky
              ? "#000"
              : isDarkTheme || isMobile
              ? "#fff"
              : "#000",
          }}
        >
          {label}
        </span>
      </Link>
      {subMenu && subMenu.length > 0 && (
        <div
          className="dropdown-btn"
          onClick={() => setIsDropdownActive(!isDropdownActive)}
        >
          {isDropdownActive ? (
            <i className="fa fa-minus-circle"></i>
          ) : (
            <i className="fa fa-plus-circle"></i>
          )}
        </div>
      )}
      <ul style={{ display: isMobile && isDropdownActive ? "block" : "none" }}>
        {subMenu.map((menu, index) => {
          const { label, to } = menu;
          return <SubNavItem key={index} label={label} to={to} />;
        })}
      </ul>
    </li>
  );
};

export default NavItem;
