import React from "react";
import CustomH2Heading from "../../components/CustomH2Heading";
import ProductCard from "../../components/ProductCard";

const TrendingSale = ({ allProducts }) => {
  return (
    <div className="product-sale">
      <div className="product-card-header-new">
        <CustomH2Heading
          title="Lightning Sale"
          isUpperCase={false}
          customClass="product-header"
        />
      </div>
      <div className="product-details">
        <div className="prod-container-w-100 d-flex prod-container-flex justify-content-start">
          {allProducts?.map((itm) => {
            return <ProductCard item={itm} />;
          })}
        </div>
        {/* <div className="bottom-button">
          <CustomButton label="View All" />
        </div> */}
      </div>
    </div>
  );
};

export default TrendingSale;
