import React from "react";
import HomeCategoryItem from "./HomeCategoryItem";
import { images } from "../../../utils/images";
import { useNavigate } from "react-router";
import AllRoutes from "../../../components/Header/AllRoutes";

const ProductCategoriesHome = () => {
  const navigate = useNavigate()
  return (
    <div className="section">
      <HomeCategoryItem
        isRight={false}
        heading="Smart Touch Switches"
        subheading="Experience the convenience, elegance, and efficiency of smart lighting control with our range of smart touch switches. Discover how these switches can enhance your living or working environment by providing effortless control."
        image={images.SMART_SWITCHES}
        buttonLabel="Explore Now"
        onClickButton={()=>navigate(AllRoutes.SMART_TOUCH_SWITCHES)}
      />
      <HomeCategoryItem
        isRight={true}
        heading="Smart Sensors"
        subheading="We Specialize in revolutionizing the way you interact with the world through cutting-edge intelligent sensing solutions with our smart Sensors."
        image={images.SMART_SENSOR}
        buttonLabel="EXPLORE NOW"
        onClickButton={() => navigate(AllRoutes.SMART_SENSORS)}
      />
      <HomeCategoryItem
        isRight={false}
        heading="Smart Door Lock"
        subheading="Upgrade your home or business with the latest in access control technology. Explore our range of smart door locks and discover the convenience, security, and peace of mind they bring."
        image={images.SMART_DOOR_LOCK}
        buttonLabel="EXPLORE NOW"
        onClickButton={() => navigate(AllRoutes.SMART_DOOR_LOCKS)}
      />
      {/* <HomeCategoryItem
        isRight={true}
        heading="Smart Curtain Controllers"
        subheading="Smart Curtain Controller offers unparalleled convenience and enhances the ambience of your space. Transform your living room into a cosy sanctuary or your office into a productive environment with just a simple command."
        image={images.SMART_CURTAIN}
        buttonLabel="EXPLORE NOW"
      /> */}
      {/* <div className="product-categories-other-image-container">
        <img
          alt="other products"
          className="product-categories-other-image-container"
          src={images.OTHER_PRODUCTS}
        />
        <CustomButton
          label="Explore Now"
          onPress={null}
          customStyle={{ marginTop: -50, height: 40, marginBottom: 50 }}
        />
      </div> */}
    </div>
  );
};

export default ProductCategoriesHome;
