import React from "react";
import CustomButton from "../../../components/CustomButton";
import CustomH2Heading from "../../../components/CustomH2Heading";
import ProductCard from "../../../components/ProductCard";
import { useNavigate } from "react-router";
import AllRoutes from "../../../components/Header/AllRoutes";

const BestSellerHome = ({ allProducts, setAllProducts }) => {
  const navigate = useNavigate()
  return (
    <div className="product-sale">
      <div className="product-card-header-new">
        <CustomH2Heading
          title="Best Sellers"
          isUpperCase={false}
          customClass="product-header"
        />
      </div>
      <div className="product-details">
        <div className="prod-container-w-100 d-flex prod-container-flex justify-content-start ">
          {allProducts?.map((itm) => {
            return <ProductCard item={itm} allProducts={allProducts} setAllProducts={setAllProducts} />;
          })}
        </div>
        <div className="bottom-button">
          <CustomButton label="View All" onPress={() => navigate(AllRoutes.SMART_TOUCH_SWITCHES)} />
        </div>
      </div>
    </div>
  );
};

export default BestSellerHome;
