import { images } from "../../utils/images";

const aboutStrings = {
  WHAT_WE_DO:
    "We are at the forefront of the automation revolution, offering cutting-edge solutions that empower businesses to streamline their processes, enhance productivity, and drive sustainable growth. As a leading automation company, our mission is to transform industries and elevate operational efficiency through innovative technologies and expert services. Elevate your living spaces with our smart home automation Products.",
  WHY_WE_DO:
    "We are driven by a passion for revolutionizing industries and empowering businesses to thrive in an increasingly automated world. As a forward-thinking automation company, our purpose is to harness the power of Technology to create smarter, more efficient, and sustainable solutions for our clients.",
};

const teamMembers = [
  {
    image: images.TEAM_PRITI,
    name: "Preety Kumari",
    designation: "Cheif Marketing Officer",
    linkedin: "https://www.linkedin.com/in/preety-kumari-bbb7b7156/",
  },
  {
    image: images.TEAM_PERSON_3,
    name: "Sanjay Lakhotia",
    designation: "Chief Intellegence Officer",
    linkedin: "https://www.linkedin.com/in/suruchigagan/",
  },
  {
    image: images.TEAM_SANDIP,
    name: "Sandip Kedia",
    designation: "Software Head",
    linkedin: "https://www.linkedin.com/in/sandip-kedia-aa1341196/",
  },
  {
    image: images.TEAM_BITTU,
    name: "Bittu Kumar",
    designation: "Sales Head",
    linkedin: "https://www.linkedin.com/in/bittu-kumar-06191b213/",
  },
];

const coreValues = [
  {
    image: images.VALUES_INTEGRITY,
    title: "Integrity",
  },
  {
    image: images.VALUES_QUALITY,
    title: "Quality",
  },
  {
    image: images.VALUES_SIMPLICITY,
    title: "Simplicity",
  },
  {
    image: images.VALUES_INNOVATION,
    title: "Innovation",
  },
  {
    image: images.VALUES_COLLABORATION,
    title: "Collaboration",
  },
  {
    image: images.VALUES_EMPOWERMENT,
    title: "Empowerment",
  },
];

const recognisedByArr = [
  {
    image: images.RECOGNISED_BY_1,
    title: "Business Standard",
  },
  {
    image: images.RECOGNISED_BY_2,
    title: "Quality",
  },
  {
    image: images.RECOGNISED_BY_3,
    title: "Inc42",
  },
  {
    image: images.RECOGNISED_BY_4,
    title: "TOI",
  },
  {
    image: images.RECOGNISED_BY_5,
    title: "Your Story",
  },
];

const clienteleArr = [
  images.CLIENT_1,
  images.CLIENT_2,
  images.CLIENT_3,
  images.CLIENT_4,
  images.CLIENT_5,
  images.CLIENT_6,
  images.CLIENT_7,
  images.CLIENT_8,
];

const foundersArr = [
  {
    image: images.TEAM_SURUCHI,
    name: "Suruchi Gagan",
    position: "Founder & CEO",
    isRight: false,
    linkedin: "https://www.linkedin.com/in/suruchigagan/",
    about:
      `As the Founder & CEO of Technixia, it is both an honor and a privilege to extend a warm greeting to all our visitors, clients, and partners. Our journey with Technixia has been one marked by innovation, dedication, and a relentless pursuit of excellence.

      From our humble beginnings, we set out with a vision  a vision to empower the world through cutting-edge IoT automation solutions. Today, I am incredibly proud of what our passionate team has achieved.
      
      At Technixia, we believe in the transformative power of technology. We constantly push the boundaries, seeking out disruptive technologies that redefine the way we live and work. Our commitment to creating smarter homes, more efficient enterprises, and sustainable communities drives us every day.
      
      What sets Technixia apart is not just our expertise but our unwavering commitment to our clients and partners. We are here to understand your unique needs and challenges, and to provide tailored solutions that make a real impact.
      
      In addition to our dedication to technological innovation, we place a strong emphasis on sustainability and social responsibility. We believe that technology should not only make our lives more convenient but also contribute to a better world for future generations.
      
      As we look to the future, I am excited about the endless possibilities that lie ahead. Technixia will continue to lead the way in IoT automation and smart solutions.
      
      Together, we will shape a smarter, more connected world.`,
  },
  {
    image: images.TEAM_SHUBHAM,
    name: "Shubham Sinha",
    position: "Founder & COO",
    isRight: true,
    linkedin: "https://www.linkedin.com/in/shubham689/",
    about:
      ` As a Founder and COO, I'm honored to guide you on a journey of discovery into the world of IoT automation and cutting-edge technology.

      Our mission at Technixia is clear: to empower individuals, businesses, and communities to embrace the full potential of the digital age. We believe in the transformative power of innovation, and our passionate team is dedicated to turning this belief into reality.
      
      With Technixia, you're not just visiting a website; you're entering a realm of limitless possibilities. Whether you're here as a potential client, partner, or simply someone curious about the future of technology, we're excited to have you with us.
      
      Explore our range of IoT automation solutions, learn about the latest industry trends, and discover how our technology can make a meaningful impact on your life and business. Our commitment to excellence, sustainability, and user-centric design shines through in everything we do.`,
  },
];

const ourMissionVisionArr = [
  {
    image: images.OUR_VISION,
    description:
      "The goal is to make people's lives smarter and better.",
    title: "Our Vision",
  },
  {
    image: images.OUR_MISSION,
    description:
      "The company's objective is to be the market leader in the lot-based electrical industry.",
    title: "Our Mission",
  },
];

const itemCourousalComponent = (item, i) => {
  return (
    <div
      className="slider-content"
      style={{
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
      key={i}
    >
      <img
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: 100,
          width: 200,
        }}
        src={item}
        alt="item"
      />
    </div>
  );
};

export {
  aboutStrings,
  teamMembers,
  clienteleArr,
  coreValues,
  recognisedByArr,
  foundersArr,
  ourMissionVisionArr,
  itemCourousalComponent,
};
