import React from "react";
import { Link } from "react-router-dom";

const FounderCard = ({ name, position, about, image, linkedin, isRight }) => {
  return (
    <div className="about-founders-card">
      <div
        className="about-founders-card-main"
        style={{ flexDirection: isRight ? "row-reverse" : "row" }}
      >
        <div className="about-founders-card-image-container">
          <div className="about-founders-card-top">
            <h4 className="about-founders-card-top-heading">{name}</h4>
            <span className="about-founders-card-top-linkedin">
              <p className="about-founders-card-top-p">{position}</p>
              <Link to={linkedin} target="_blank">
                <i
                  className="fa fa-linkedin-square linkedin-icon"
                  aria-hidden="true"
                ></i>
              </Link>
            </span>
          </div>
          <div className="about-founders-card-bottom">
            <img
              src={image}
              alt={name}
              style={{ alignSelf: isRight ? "flex-start" : "flex-end" }}
              className="about-founders-card-left-img"
            />
          </div>
        </div>
        <div className="about-founders-card-description-container">
          <p className="about-founders-card-right-des">{about}</p>
        </div>
      </div>
    </div>
  );
};

export default FounderCard;
