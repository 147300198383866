const images = {
  SMART_SENSOR: require("../assets/images/Smart_Sensors.jpg"),
  SMART_SWITCHES: require("../assets/images/Smart_switches.jpg"),
  SMART_DOOR_LOCK: require("../assets/images/Smart_Door_Locks.jpg"),
  SMART_CURTAIN: require("../assets/images/Smart_Curtain.jpg"),
  OTHER_PRODUCTS: require("../assets/images/Other_Products.jpg"),
  RECOGNISED_BY_1: require("../assets/images/recognized_1.png"),
  RECOGNISED_BY_2: require("../assets/images/recognized_2.png"),
  RECOGNISED_BY_3: require("../assets/images/recognized_3.png"),
  RECOGNISED_BY_4: require("../assets/images/recognized_4.png"),
  RECOGNISED_BY_5: require("../assets/images/recognized_5.png"),
  FOOTPRINT_1: require("../assets/images/5 star Reviews.png"),
  FOOTPRINT_2: require("../assets/images/Android & IOS users.png"),
  FOOTPRINT_3: require("../assets/images/Cities.png"),
  FOOTPRINT_4: require("../assets/images/Product sold.png"),
  FOOTPRINT_5: require("../assets/images/Smart Homes.png"),
  WHYTECHNIXIA_IMAGE: require("../assets/images/Photo 445px 638px.png"),
  WHYTECHNIXIA_LOGO_1: require("../assets/images/Call Icon.png"),
  WHYTECHNIXIA_LOGO_2: require("../assets/images/Completely Retrofit.png"),
  WHYTECHNIXIA_LOGO_3: require("../assets/images/End to End Secure.png"),
  WHYTECHNIXIA_LOGO_4: require("../assets/images/Full Stack Solution.png"),
  WHYBUYFROMTECHNIXIA_1: require("../assets/images/Exclusive Offers.png"),
  WHYBUYFROMTECHNIXIA_2: require("../assets/images/Free Installation.png"),
  WHYBUYFROMTECHNIXIA_3: require("../assets/images/Free Shipping.png"),
  WHYBUYFROMTECHNIXIA_4: require("../assets/images/No Emi Cost.png"),
  WHYBUYFROMTECHNIXIA_5: require("../assets/images/Secured Payment.png"),
  CUSTOMIZATION_BACKGROUND: require("../assets/images/customization_background.png"),
  CUSTOMIZATION_LOCK: require("../assets/images/customization_1_lock.png"),
  CUSTOMIZATION_CHOOSE_SWITCH: require("../assets/images/customization_1_choose_switch.png"),
  CUSTOMIZATION_INDOOR: require("../assets/images/customization_1_indoor.png"),
  CUSTOMIZATION_OFFLINE: require("../assets/images/customization_1_offline.png"),
  CUSTOMIZATION_FRAME: require("../assets/images/customization_1_frame.png"),
  CUSTOMIZATION_MODULAR: require("../assets/images/customization_1_modular.png"),
  DEFAULT_AVTAAR: require("../assets/images/avtaar.png"),
  TECHNIXIA_LOGO: require("../assets/images/FinalLogo.png"),
  CONTACT_IMAGE: require("../assets/images/Contact.jpg"),
  FOOTER_BACKGROUND: require("../assets/images/footer_background.png"),
  ABOUT_BACKGROUND: require("../assets/images/about_background.jpg"),
  OUR_JOURNEY: require("../assets/images/our_journey.png"),
  OUR_JOURNEY_MOBILE: require("../assets/images/our_journey_mobile.jpeg"),
  OUR_MISSION: require("../assets/images/our_mission.png"),
  OUR_VISION: require("../assets/images/our_vision.png"),
  TEAM_SURUCHI: require("../assets/images/suruchi_gagan.jpeg"),
  TEAM_SHUBHAM: require("../assets/images/shubham_sinha.jpeg"),
  TEAM_SANDIP: require("../assets/images/sandip_kedia.jpeg"),
  TEAM_PERSON_3: require("../assets/images/person_3.jpeg"),
  TEAM_PRITI: require("../assets/images/priti_byahut.jpeg"),
  TEAM_BITTU: require("../assets/images/bittu.jpeg"),
  TEAM_NIRBAN: require("../assets/images/nirban_khan.jpeg"),
  VALUES_COLLABORATION: require("../assets/images/values_collaboration.png"),
  VALUES_EMPOWERMENT: require("../assets/images/values_empowerment.png"),
  VALUES_INNOVATION: require("../assets/images/values_innovation.png"),
  VALUES_INTEGRITY: require("../assets/images/values_integrity.png"),
  VALUES_QUALITY: require("../assets/images/values_quality.png"),
  VALUES_SIMPLICITY: require("../assets/images/values_simplicity.png"),
  CLIENT_1: require("../assets/images/client_1.png"),
  CLIENT_2: require("../assets/images/client_2.png"),
  CLIENT_3: require("../assets/images/client_3.png"),
  CLIENT_4: require("../assets/images/client_4.png"),
  CLIENT_5: require("../assets/images/client_5.png"),
  CLIENT_6: require("../assets/images/client_6.png"),
  CLIENT_7: require("../assets/images/client_7.png"),
  CLIENT_8: require("../assets/images/client_8.png"),
  SWITCHES_BANNER: require("../assets/images/switches_banner.jpg"),
  SWITCHES_BANNER_MOBILE: require("../assets/images/switches_banner_mobile.jpg"),
  LOCKS_BANNER_MOBILE: require("../assets/images/Smart_Locks_Desktop_Banner_Mobile.jpg"),
  LOCKS_BANNER: require("../assets/images/Smart_Locks_Desktop_Banner.jpg"),
  SMART_SENSOR_BANNER: require("../assets/images/sensor_banner.jpg"),
  SMART_SENSOR_BANNER_MOBILE: require("../assets/images/sensor_banner_mobile.jpg"),
  LOGO_LOADER: require("../assets/images/logo_loader.gif"),
  MODULE_SWITCHES_2: require("../assets/images/2_module_1.png"),
  MODULE_SWITCHES_4: require("../assets/images/4_module_1.png"),
  MODULE_SWITCHES_6: require("../assets/images/6_module_1.png"),
  MODULE_SWITCHES_8: require("../assets/images/8_module_1.png"),
  MODULE_SWITCHES_12: require("../assets/images/12_module_1.png"),
  PRODUCT_WARRANTY_IMAGE: require("../assets/images/warranty_background.png"),
  MODULE_SWITCHES_2_MOBILE: require("../assets/images/2_module_mobile.png"),
  MODULE_SWITCHES_4_MOBILE: require("../assets/images/4_module_mobile.png"),
  MODULE_SWITCHES_6_MOBILE: require("../assets/images/6_module_mobile.png"),
  MODULE_SWITCHES_8_MOBILE: require("../assets/images/8_module_mobile.png"),
  MODULE_SWITCHES_12_MOBILE: require("../assets/images/12_module_mobile.png"),
};

const icons = {
  CALL_ICON: require("../assets/icons/call_icon.png"),
  FB_ICON: require("../assets/icons/facebook_icon.png"),
  INSTAGRAM_ICON: require("../assets/icons/instagram_icon.png"),
  LINKEDIN_ICON: require("../assets/icons/linkdin.png"),
  LOCATION_ICON: require("../assets/icons/location_icon.png"),
  MAIL_ICON: require("../assets/icons/mail_icon.png"),
  TELEGRAM_ICON: require("../assets/icons/telegram.png"),
  TWITTER_ICON: require("../assets/icons/twitter_icon.png"),
  WHATSAPP_ICON: require("../assets/icons/whatsapp.png"),
  YOUTUBE_ICON: require("../assets/icons/youtube.png"),
  PARTNER_ICON: require("../assets/icons/become_a_partner_2.png"),
  PROJECTS_ICON: require("../assets/icons/your_projects_2.png"),
  SUPPORT_ICON: require("../assets/icons/call_us_2.png"),
  GOOGLE_BUSINESS_ICON: require("../assets/icons/google_business.png"),
  WARRANTY_ICON: require("../assets/icons/warranty_icon.png"),
};

export { images, icons };
