import React, { useEffect, useState } from "react";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import CustomButton from "../../../components/CustomButton";
import Header from "../../../components/Header/Header";

const SliderHome = ({ navigate, allBanner }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  useEffect(() => {
    const updateDimension = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [windowWidth]);

  return (
    <div>
      <Header isDarkTheme={allBanner[activeSlideIndex]?.isDarkTheme} />
      <Slider
        touchDisabled={true}
        autoplay={1500}
        onSlideChange={(event) => setActiveSlideIndex(event.slideIndex)}
        infinite
        className="slider-wrapper"
      >
        {allBanner?.map((item, index) => {
          const images = item?.backgroundImg;
          let img = "";

          if (windowWidth < images?.[0].maxWidth) {
            img = images?.[0].image;
          } else if (windowWidth < images?.[1].maxWidth) {
            img = images?.[1].image;
          } else if (windowWidth < images?.[2].maxWidth) {
            img = images?.[2].image;
          } else if (windowWidth < images?.[3].maxWidth) {
            img = images?.[3].image;
          } else {
            img = images?.[4].image;
          }
          return (
            <div
              key={index}
              className="slider-content animated "
              style={{
                backgroundImage: `url(${img})`,
              }}
            >
              {item?.canShowButton && (
                <div className="inner">
                  <div className="slider-button-div">
                    <CustomButton
                      onPress={() => navigate(item?.buttonNavigateTo)}
                      label={item?.buttonLabel}
                      customStyle={{
                        backgroundColor: item?.isDarkTheme
                          ? "#ffffff "
                          : "#000000",
                        color: item?.isDarkTheme ? "#000000 " : "#ffffff",
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default SliderHome;
