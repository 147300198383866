import React from "react";
import ProductSubcategoryCard from "../../components/ProductSubcategoryCard";

const ProductDisplay = ({allSubcategories}) => {
  return (
    <>
      <div className="prod-box-container">
        <div className="prod-container d-flex justify-content-center">
          {allSubcategories?.map((item) => {
            return <ProductSubcategoryCard item={item} />;
          })}
        </div>
      </div>
      {/* <div className="prod-button">
        <CustomButton label="BUILD YOUR SWITCHBOARD" />
      </div> */}
    </>
  );
};

export default ProductDisplay;
