import React, { useEffect, useState } from "react";
import { images } from "../../utils/images";
import CustomH2Heading from "../CustomH2Heading";
import ApiCall from "../../api/ApiCall";
import { customerRoutes } from "../../api/Routes";

const TestiMonial = () => {
  const [page, setPage] = useState(1);
  const [allCustomerReview, setAllCustomerReview] = useState([]);

  useEffect(() => {
    const handleGetReviewData = async () => {
      const data = {
        pageNumber: page,
        pageLimit: 3,
      };
      const res = await ApiCall("post", customerRoutes.getCustomerReview, data);
      setAllCustomerReview(res?.reviews?.data);
    };

    handleGetReviewData();
  }, [page]);

  return (
    <section className="testimonials about-heading">
      <div className="testimonial-top">
        <h6 className="testimonial-heading">TESTIMONIALS</h6>
        <CustomH2Heading title="What Our Clients Say About Us" />
        <p className="testimonial-subheading">
          Customer experience during our place, and we share it with you
          as a reference to believe in us
        </p>
      </div>
      <div className="testimonials-highlights row">
        {allCustomerReview?.slice(0, 3).map((user) => {
          return (
            <div className="testimonials-highlights-content col-md-6 col-sm-12 col-lg-4 col-12">
              <div className="testimonial-user">
                <div className="testimonial-user-image">
                  <img
                    src={images.DEFAULT_AVTAAR}
                    alt="default user"
                    className="testimonial-user-image-img"
                  />
                </div>
                <div className="testimonial-user-text">
                  <h3 className="testimonial-user-name">{user?.name}</h3>
                  <small className="testimonial-user-time">Customer</small>
                </div>
              </div>
              <div className="testimonial-user-review">
                <p className="testimonial-user-review-text">
                  “{user?.feedbackMessage}”
                </p>
              </div>
              <div className="testimonial-user-star-rating">
                <i
                  className="fa fa-star"
                  aria-hidden="true"
                  style={{ color: user?.stars >= 1 ? "#04592b" : "#e8e8e8" }}
                ></i>
                <i
                  className="fa fa-star"
                  aria-hidden="true"
                  style={{ color: user?.stars >= 2 ? "#04592b" : "#e8e8e8" }}
                ></i>
                <i
                  className="fa fa-star"
                  aria-hidden="true"
                  style={{ color: user?.stars >= 3 ? "#04592b" : "#e8e8e8" }}
                ></i>
                <i
                  className="fa fa-star"
                  aria-hidden="true"
                  style={{ color: user?.stars >= 4 ? "#04592b" : "#e8e8e8" }}
                ></i>
                <i
                  className="fa fa-star"
                  aria-hidden="true"
                  style={{ color: user?.stars >= 5 ? "#04592b" : "#e8e8e8" }}
                ></i>
              </div>
            </div>
          );
        })}
      </div>
      {/* <div className="testimonials-button">
        <CustomButton label="View All" />
      </div> */}
    </section>
  );
};

export default TestiMonial;
