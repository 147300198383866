import AllRoutes from "./AllRoutes";

const MainNavRoutes = [
  {
    label: "Home",
    to: AllRoutes.HOME,
  },
  {
    label: "Products",
    to: AllRoutes.PORTFOLIO,
  },
  {
    label: "Blog",
    to: AllRoutes.BLOG,
  },
  {
    label: "Contact",
    to: AllRoutes.CONTACTS,
  },
  {
    label: "About",
    to: AllRoutes.ABOUT,
  },
];

const PortolioNavRoutes = [
  {
    label: "Smart Touch Switches",
    to: AllRoutes.SMART_TOUCH_SWITCHES,
  },
  {
    label: "Smart Sensors",
    to: AllRoutes.SMART_SENSORS,
  },
  {
    label: "Smart Locks",
    to: AllRoutes.SMART_DOOR_LOCKS,
  },
];

const AboutNavRoutes = [];

const HomeRoutes = [];

const ContactRoutes = [];

export {
  HomeRoutes,
  MainNavRoutes,
  PortolioNavRoutes,
  AboutNavRoutes,
  ContactRoutes,
};
