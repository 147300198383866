import React from "react";
import { images } from "../../../utils/images";
import CustomH2Heading from "../../../components/CustomH2Heading";

const OurFootprintsSectionHome = () => {
  return (
    <section>
      <div className="footprint">
        <div className="footprint-header">
          <CustomH2Heading customClass="footprint-heading" title={'OUR FOOTPRINTS'} /> 
        </div>
        <div className="footprint-items">
          <div className="footprint-item">
            <img className="footprint-image" src={images.FOOTPRINT_1} alt="footprint" />
            <p className="footprint-item-details">
              50 K+ <br />
              Smart Homes
            </p>
          </div>
          <div className="footprint-item">
            <img className="footprint-image" src={images.FOOTPRINT_2} alt="footprint" />
            <p className="footprint-item-details">
              32 K+ <br />
              Android & iOS Users
            </p>
          </div>
          <div className="footprint-item">
            <img className="footprint-image" src={images.FOOTPRINT_3} alt="footprint" />
            <p className="footprint-item-details">
              25 K+ <br />
              Cities
            </p>
          </div>
          <div className="footprint-item">
            <img className="footprint-image" src={images.FOOTPRINT_4} alt="footprint" />
            <p className="footprint-item-details">
              200 K+ <br />
              Products Sold
            </p>
          </div>
          <div className="footprint-item">
            <img className="footprint-image" src={images.FOOTPRINT_5} alt="footprint" />
            <p className="footprint-item-details">
              200 K+ <br />5 Start Reviews
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurFootprintsSectionHome;
