import React, { useEffect, useState } from "react";
import GetQuoteModal from "../../components/GetQuoteModal";

const TopSection = ({ productDetails }) => {
  const [selectedImage, setSelectedImage] = useState();
  const [showModal, setShowModal] = useState(false);
  const [count, setCount] = useState(0);

  const lightningOffer = productDetails?.lightningOffer?.isLightningOffer;

  useEffect(() => {
    setSelectedImage(productDetails?.images?.[0]);
  }, [productDetails]);

  return (
    <>
      <div style={{ marginTop: 100 }}>
        <div className="product-topsection-main min-vh-100">
          <div className="product-topsection-left animated slideInLeft">
            <div className="product-topsection-left-img-cont">
              <img
                className="product-topsection-left-img"
                src={selectedImage}
                alt="product"
              />
              <div className="product-topsection-left-smalls">
                {productDetails?.images?.map((image, index) => {
                  return (
                    <div key={index}>
                      <div
                        onMouseOver={() => {
                          setSelectedImage(image);
                        }}
                        className="product-topsection-left-small"
                        onClick={() => {
                          setSelectedImage(image);
                        }}
                      >
                        <img
                          alt="details"
                          src={image}
                          className="product-topsection-left-img-small"
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="product-topsection-right animated slideInRight">
            <div className="product-topsection-right-top">
              <h1 className="product-topsection-right-heading">
                {productDetails?.name} <br />
              </h1>

              {/* {lightningOffer ? (
                <>
                  <div className="product-topsection-right-prices">
                    <p className="product-topsection-right-price-left">
                      Rs {productDetails?.leastPrice} /-
                    </p>
                    <p className="product-topsection-right-price-right">
                      Rs {productDetails?.basePrice} /-
                    </p>
                  </div>
                  <div className="product-topsection-right-offer-green-box">
                    <h4 className="product-topsection-right-offer">
                      {productDetails?.offer}% off
                    </h4>
                  </div>
                </>
              ) : (
                <div className="product-topsection-right-prices">
                  <p className="product-topsection-right-price-left">
                    Rs {productDetails?.basePrice} /-
                  </p>
                </div>
              )} */}

              <div className="product-topsection-offer-section-line"></div>
              <div className="product-topsection-star">
                <i
                  className="fa fa-star"
                  aria-hidden="true"
                  style={{ color: "#ffd800" }}
                ></i>
                <i
                  className="fa fa-star"
                  aria-hidden="true"
                  style={{ color: "#ffd800" }}
                ></i>
                <i
                  className="fa fa-star"
                  aria-hidden="true"
                  style={{ color: "#ffd800" }}
                ></i>
                <i
                  className="fa fa-star"
                  aria-hidden="true"
                  style={{ color: "#ffd800" }}
                ></i>
                <i
                  className="fa fa-star"
                  aria-hidden="true"
                  style={{ color: "#ffd800" }}
                ></i>
              </div>
              <div className="product-topsection-text-section">
                <p className="product-topsection-text">
                  {productDetails?.details}
                </p>
              </div>
              {/* <div className="product-topsection-color-section">
                <p className="product-topsection-color-text"> Color</p>
                <div className="product-topsection-all-colors">
                  <div className="product-topsection-all-color-1"></div>
                  <div className="product-topsection-all-color-2"></div>
                  <div className="product-topsection-all-color-3"></div>
                </div>
              </div>
              <div className="product-topsection-color-section-frame">
                <p className="product-topsection-color-text-frame">
                  Frame Color
                </p>
                <div className="product-topsection-all-colors-frame">
                  <div className="product-topsection-all-color-1-frame"></div>
                  <div className="product-topsection-all-color-2-frame"></div>
                  <div className="product-topsection-all-color-3-frame"></div>
                  <div className="product-topsection-all-color-4-frame"></div>
                </div>
              </div> */}
              <div className="product-topsection-stock">
                <div className="product-topsection-stock-text"> Stock : </div>
                <div className="product-topsection-stock-green-box">
                  <p className="product-topsection-stock-green-box-text">
                    available
                  </p>
                </div>
              </div>
            </div>
            <div className="product-topsection-foot-green-box">
              <div className="product-topsection-foot-green-box-item-1">
                <div className="quantity-box">
                  <p
                    onClick={() => {
                      if (count > 0) {
                        setCount(count - 1);
                      } else {
                        alert("You can't remove anymore");
                      }
                    }}
                    className="quantity-button-minus"
                  >
                    -
                  </p>
                  <div className="quantity-input">{count}</div>
                  <p
                    onClick={() => {
                      if (count < 10) {
                        setCount(count + 1);
                      } else {
                        alert("You can't add anymore");
                      }
                    }}
                    className="quantity-button-plus"
                  >
                    +
                  </p>
                </div>
              </div>
              <div className="product-topsection-foot-green-box-item-2">
                <button
                  className="add-to-cart-button"
                  onClick={() => setShowModal(!showModal)}
                >
                  <span>
                    <i
                      className="fa fa-cart-plus"
                      aria-hidden="true"
                      style={{ marginRight: 10, fontSize: 18 }}
                    ></i>
                    Get Quote
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GetQuoteModal
        showModal={showModal}
        setShowModal={setShowModal}
        productDetails={productDetails}
      />
    </>
  );
};

export default TopSection;
