import React from "react";
import { Link } from "react-router-dom";
import constants from "../utils/constants";

const CustomBottomButton = () => {
  return (
    <Link to={constants.WHATSAPP_LINK} target="_blank">
      <button className="bottom-button-container">
        <i
          class="fa fa-whatsapp bottom-icon"
          style={{ fontSize: 28 }}
          aria-hidden="true"
        ></i>
        Get an Expert Help
      </button>
      <button className="bottom-button-container-mobile">
        <i
          class="fa fa-whatsapp bottom-icon-mobile"
          style={{ fontSize: 28 }}
          aria-hidden="true"
        ></i>
      </button>
    </Link>
  );
};

export default CustomBottomButton;
