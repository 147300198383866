import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import { images } from "../../utils/images";
import CustomH2Heading from "../../components/CustomH2Heading";
import ProductDisplay from "./ProductDisplay";
import BestSeller from "./BestSeller";
import TrendingSale from "./TrendingSale";
import TestiMonial from "../../components/Testimonial/TestimonialGlobal";
import AboutProduct from "./AboutProduct";
import CustomResponsiveImage from "../../components/CustomResponsiveImage";
import {
  getBestSellerProducts,
  getTrandingProducts,
  getproductSubCategories,
} from "../../globalFunctions/globalFunctions";
import Footer from "../../components/Footer";
import Loader from "../../components/Loader";
import BookDemoModal from "../../components/BookDemoModal";
import { getBannerData } from "../../globalFunctions/globalFunctions";
import { bannerKeys } from "../../utils/constants";

const SmarDoorLocksCategory = () => {
  const categoryId = "6576b007392732f986eba22e";
  const [showLoader, setShowLoader] = useState(true);
  const [showModalFreeDemo, setShowModalFreeDemo] = useState(false);
  const [allBanner, setAllBanner] = useState(null);

  const [allSubcategories, setAllSubcategories] = useState(null);
  const [productsSale, setProductsSale] = useState(null);
  const [bestSellerProducts, setBestSellerProducts] = useState(null);

  useEffect(() => {
    getTrandingProducts(categoryId, setProductsSale);
    getproductSubCategories(categoryId, setAllSubcategories);
    getBestSellerProducts(categoryId, setBestSellerProducts);
    getBannerData(bannerKeys.SMART_LOCKS, setAllBanner);
  }, []);

  useEffect(() => {
    if (
      bestSellerProducts !== null &&
      allSubcategories !== null &&
      productsSale !== null &&
      allBanner !== null
    ) {
      setShowLoader(false);
    }
  }, [bestSellerProducts, allSubcategories, productsSale, allBanner]);

  const mainBanner = allBanner?.[0];
  const imagesArr = mainBanner?.backgroundImg;

  return showLoader ? (
    <Loader />
  ) : (
    <div>
      <section>
        <Header />
      </section>
      <div className="product-category-header">
        <div className="product-category-banner">
          <CustomResponsiveImage
            imageArr={imagesArr}
            className="prod-banner"
          />
        </div>
      </div>
      <div className="product-container">
        <div className="product-card-header-new">
          <CustomH2Heading
            title="Smart Locks"
            isUpperCase={true}
            customClass="product-header"
          />
        </div>
        <div className="product-product-description">
          Unlock the future with Smart Door Locks - where security meets
          intelligence
        </div>
      </div>
      {allSubcategories && allSubcategories.length > 0 && (
        <ProductDisplay allSubcategories={allSubcategories} />
      )}
      {bestSellerProducts && bestSellerProducts.length > 0 && (
        <BestSeller allProducts={bestSellerProducts} />
      )}
      {productsSale && productsSale.length > 0 && (
        <TrendingSale allProducts={productsSale} />
      )}
      <AboutProduct
        img={images.SMART_DOOR_LOCK}
        description={
          "Smart locks are innovative electronic locks designed to provide enhanced security and convenience for homes and businesses. Unlike traditional locks that require physical keys, smart locks utilize advanced technology to enable remote access, control, and monitoring of access points. Smart locks offer keyless entry options, allowing users to unlock doors using various methods such as smartphone apps, key fobs, fingerprint recognition, or PIN codes. This eliminates the need for physical keys and the risk of losing or misplacing them. Smart locks allow users to create temporary access codes for specific periods, granting entry to visitors without the need to provide physical keys. These codes can be easily deleted or changed, ensuring better control over access."
        }
        onButtonPress={() => setShowModalFreeDemo(true)}
      />
      <TestiMonial />
      <BookDemoModal
        setShowModal={setShowModalFreeDemo}
        showModal={showModalFreeDemo}
      />
      <Footer />
    </div>
  );
};

export default SmarDoorLocksCategory;
