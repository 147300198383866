import React, { useEffect, useState } from "react";
import ApiCall from "../api/ApiCall";
import { customerRoutes } from "../api/Routes";
import CustomModal from "./CustomModal";
import { analyticsInstance } from "../globalFunctions/BaseTracking";
import constants from "../utils/constants";
import { map } from "lodash";

const BookDemoModal = ({ showModal, setShowModal }) => {
  const [name, setname] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [email, setEmail] = useState(null);
  const [message, setMessage] = useState(null);
  const [addr1, setAddr1] = useState(null);
  const [addr2, setAddr2] = useState(null);
  const [visitorMessage, setVisitorMessage] = useState(null);
  const [visitorState, setVisitorState] = useState(null);
  const [visitorCity, setVisitorCity] = useState(null);
  const [visitDate, setVisitDate] = useState(null);
  const [allSelected, setAllSelected] = useState(false);
  const [selectedHometype, setSelectedHometype] = useState(null);
  const [selectedStages, setSelectedStages] = useState(null);
  const [selectedSlots, setSelectedSlots] = useState(null);
  const [selectedAutomation, setSelectedAutomation] = useState(null);

  let lookingFor = ["Existing Home", "New Home"];
  let typesAutomation = [
    "Commercial Automation",
    "Residential Automation",
    "Industrial Automation ",
  ];

  let automationStages = [
    "Interior works started",
    "Electrical wiring  started",
    "Not Yet Started",
  ];

  let allSlots = ["10AM - 1PM", "2PM - 5PM"];

  const [productInterests, setproductInterests] = useState([
    { label: "Smart touch switches", isSelected: false },
    { label: "Smart locks", isSelected: false },
    { label: "Motorised curtain", isSelected: false },
    { label: "Motorised blinds", isSelected: false },
    { label: "Wardrobes locks", isSelected: false },
    { label: "Washroom sensors", isSelected: false },
    { label: "Passage sensors", isSelected: false },
    { label: "smart sensors", isSelected: false },
  ]);

  const emailValidation =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const handleReset = () => {
    setname(null);
    setPhoneNumber(null);
    setEmail(null);
    setMessage(null);
    setAddr1(null);
    setAddr2(null);
    setVisitorMessage(null);
    setVisitorState(null);
    setVisitorCity(null);
    setVisitDate(null);
    setAllSelected(false);
    setSelectedHometype(null);
    setSelectedStages(null);
    setSelectedSlots(null);
    setSelectedAutomation(null);
    setShowModal(false);
    resetClickProductInterests()
  };

  const handleClickProductInterests = (index) => {
    const tempProductInterest = productInterests?.map((item, i) => {
      if (i === index) {
        return { ...item, isSelected: !item.isSelected };
      }
      return item;
    });
    setproductInterests(tempProductInterest);
  };

  const resetClickProductInterests = () => {
    const tempProductInterest = productInterests?.map((item, i) => {
      return { ...item, isSelected: false };
    });
    setproductInterests(tempProductInterest);
  };

  const handleSelectCheckBox = (e) => {
    const isChecked = e.target.checked;

    const tempProductInterest = productInterests?.map((item) => ({
      ...item,
      isSelected: isChecked,
    }));
    setproductInterests(tempProductInterest);
  };

  useEffect(() => {
    setAllSelected(productInterests.every((item) => item.isSelected));
  }, [productInterests]);

  const handleBookDemoPress = async (e) => {
    e.preventDefault();
    let tempProducts = [];
    map(productInterests, (item) => {
      if (item?.isSelected) {
        tempProducts.push(item?.label);
      }
    });
    if (
      selectedAutomation !== null &&
      tempProducts.length > 0 &&
      selectedStages !== null &&
      name !== null &&
      phoneNumber !== null &&
      addr1 !== null &&
      visitorState !== null &&
      visitorCity !== null &&
      visitDate !== null &&
      selectedSlots !== null
    ) {
      const data = {
        automationType: typesAutomation[selectedAutomation],
        homeType: lookingFor[selectedHometype],
        products: tempProducts,
        stagesOfAutomation: automationStages[selectedStages],
        customerName: name,
        customerEmail: email,
        customerPhone: phoneNumber,
        address1: addr1,
        address2: addr2,
        state: visitorState,
        city: visitorCity,
        message: visitorMessage,
        visitDate: visitDate,
        visitTime: allSlots[selectedSlots],
      };
      const res = await ApiCall(
        "post",
        customerRoutes.addFreeDemoRequest,
        data
      );
      if (res?.success) {
        const productData = {
          customerName: name,
          customerEmail: email,
          customerPhone: phoneNumber,
          requestMessage: message,
        };
        analyticsInstance().event({
          category: constants.PRODUCT,
          action: "product_quote_request",
          label: "Product Quote Request",
          options: productData,
        });
        handleReset();
        alert(
          "FREE Demo Request Submitted Successfully! Our Team will Reach out you very Soon. Thank you"
        );
      }
    } else {
      alert("Fill all Required Fields");
    }
  };

  const demoForm = () => {
    return (
      <div className="booking-form-w-100 w-lg-50">
        <div className="popup-stages-automation">
          <p className="stages-header">Type Of Your Automation? *</p>
          <div className="popup-btn-container">
            {typesAutomation?.map((text, index) => {
              return (
                <button
                  className="popup-btn"
                  value={index}
                  onClick={() => {
                    setSelectedAutomation(index);
                  }}
                  style={{
                    backgroundColor:
                      selectedAutomation === index ? "#04592b" : "#fff",
                    color: selectedAutomation === index ? "#fff" : "#000",
                    border:
                      selectedAutomation === index
                        ? "1px solid #04592b"
                        : "1px solid #416082",
                  }}
                >
                  {text}
                </button>
              );
            })}
          </div>
        </div>
        {selectedAutomation === 1 && (
          <div className="popup-stages-automation">
            <div className="card-left-header-auto">
              <p className="stages-header">Looking Automation For ?</p>
              <div className="popup-btn-container">
                {lookingFor?.map((text, index) => {
                  return (
                    <button
                      className="popup-btn"
                      onClick={() => {
                        setSelectedHometype(index);
                      }}
                      style={{
                        backgroundColor:
                          selectedHometype === index ? "#04592b" : "#fff",
                        color: selectedHometype === index ? "#fff" : "#000",
                        border:
                          selectedHometype === index
                            ? "1px solid #04592b"
                            : "1px solid #416082",
                      }}
                    >
                      {text}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        <div className="popup-stages-automation">
          <div className="left-side">
            <div className="card-left-header">
              <p className="stages-header">Products Of Your Interest? *</p>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  checked={allSelected}
                  onChange={handleSelectCheckBox}
                />
                <span>All</span>
              </div>
            </div>
            <div>
              <div className="popup-btn-container">
                {productInterests?.map((text, index) => {
                  return (
                    <button
                      className="popup-btn"
                      onClick={() => {
                        handleClickProductInterests(index);
                      }}
                      style={{
                        backgroundColor:
                          text?.isSelected === true ? "#04592b" : "#fff",
                        color: text?.isSelected === true ? "#fff" : "#000",
                        border:
                          text?.isSelected === true
                            ? "1px solid #04592b"
                            : "1px solid #416082",
                      }}
                    >
                      {text?.label}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="popup-stages-automation">
          <p className="stages-header">Stages Of Your Automation? *</p>
          <div className="popup-btn-container">
            {automationStages?.map((text, index) => {
              return (
                <button
                  className="popup-btn"
                  onClick={() => {
                    setSelectedStages(index);
                  }}
                  style={{
                    backgroundColor:
                      selectedStages === index ? "#04592b" : "#fff",
                    color: selectedStages === index ? "#fff" : "#000",
                    border:
                      selectedStages === index
                        ? "1px solid #04592b"
                        : "1px solid #416082",
                  }}
                >
                  {text}
                </button>
              );
            })}
          </div>
        </div>
        <div className="book-demo-form popup-stages-automation">
          <div className="small-input-boxes">
            <input
              className="popup-custom-textarea"
              type="text"
              value={name}
              onChange={(e) => setname(e.target.value)}
              placeholder="Full Name *"
              required
            />
            <input
              className="popup-custom-textarea"
              type="text"
              value={phoneNumber}
              required
              maxlength="10"
              inputmode="numeric"
              minLength="10"
              onChange={(e) => {
                if (/^[0-9]*$/.test(e.target.value)) {
                  setPhoneNumber(e.target.value);
                }
              }}
              placeholder="Mobile No. *"
            />
            <input
              className="popup-custom-textarea"
              type="email"
              value={email}
              pattern={emailValidation}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email id"
            />
            <input
              className="popup-custom-textarea"
              value={visitorState}
              onChange={(e) => setVisitorState(e.target.value)}
              type="text"
              placeholder="Select State *"
            />
            <input
              className="popup-custom-textarea"
              type="text"
              value={visitorCity}
              onChange={(e) => setVisitorCity(e.target.value)}
              placeholder="Select City *"
            />
            <input
              className="popup-custom-textarea"
              type="text"
              value={addr1}
              onChange={(e) => setAddr1(e.target.value)}
              placeholder="Address line 1 *"
            />
            <input
              className="popup-custom-textarea"
              type="text"
              value={addr2}
              onChange={(e) => setAddr2(e.target.value)}
              placeholder="Address line 2"
            />
            <textarea
              className="popup-custom-textarea"
              placeholder="Message"
              value={visitorMessage}
              onChange={(e) => setVisitorMessage(e.target.value)}
            ></textarea>
          </div>
        </div>
        <div className="popup-text-container popup-stages-automation">
          <div className="popup-footer-text">
            <p className="stages-header">When Would You Like To Visit? *</p>
            <div className="popup-date-input">
              <input
                type="date"
                placeholder="Date"
                value={visitDate}
                onChange={(e) => setVisitDate(e.target.value)}
              />
            </div>
          </div>
          <div className="popup-footer-text">
            <p className="stages-header">Select Time Slot *</p>
            <div className="popup-btn-container-slots">
              {allSlots?.map((slot, index) => {
                return (
                  <button
                    className="popup-btn w-auto "
                    onClick={() => {
                      setSelectedSlots(index);
                    }}
                    style={{
                      backgroundColor:
                        selectedSlots === index ? "#04592b" : "#fff",
                      color: selectedSlots === index ? "#fff" : "#000",
                      border:
                        selectedSlots === index
                          ? "1px solid #04592b"
                          : "1px solid #416082",
                    }}
                  >
                    {slot}
                  </button>
                );
              })}
            </div>
          </div>
        </div>

        <div className="small-button-container">
          <button className="small-button" onClick={handleBookDemoPress}>
            Book Demo
          </button>
        </div>
      </div>
    );
  };

  return (
    <CustomModal
      showModal={showModal}
      innerComponent={demoForm}
      text="Book a Free Demo"
      customClass="popup-header"
      onCancelPress={handleReset}
    />
  );
};

export default BookDemoModal;
