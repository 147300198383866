import React from "react";
import CustomButton from "../../../components/CustomButton";

const HomeCategoryItem = ({
  isRight = "false",
  heading,
  subheading,
  image,
  buttonLabel,
  onClickButton
}) => {
  return (
    <div
      className={`container-fluid justify-content-between align-items-center  ${
        isRight
          ? "product-categories-container"
          : "product-categories-container-reverse"
      }`}
    >
      <div className="product-categories-right-part px-5 animated slideInRight">
        <div className="services-title">
          <h2 className="fw-fw-bolder">{heading}</h2>
          <div className="services-description py-3">
            <p>{subheading}</p>
          </div>
        </div>
        <CustomButton
          label={buttonLabel}
          onPress={onClickButton}
          customStyle={{ marginTop: 10 }}
        />
      </div>
      <div className="product-categories-left-part animated slideInLeft">
        <img
          src={image}
          alt="product"
          className="product-categories-left-part-img"
        />
      </div>
    </div>
  );
};

export default HomeCategoryItem;
