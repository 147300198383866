import React from "react";
import { icons, images } from "../../utils/images";

const ProductsWarranty = () => {
  return (
    <div
      className="products-warranty-container"
      style={{ background: `url(${images.PRODUCT_WARRANTY_IMAGE})` }}
    >
      <img src={icons.WARRANTY_ICON} alt="icon" className="warranty-icon" />
    </div>
  );
};

export default ProductsWarranty;
