import React from "react";
import Header from "../../components/Header/Header";
import constants from "../../utils/constants";
import Footer from "../../components/Footer";

const PrivacyPolicy = () => {
  return (
    <div>
      <section>
        <Header isDarkTheme={true} />
      </section>
      <section className="page-title">
        <div
          className="cws-image-bg"
          style={{ backgroundImage: `url(${constants.HEADER_BACKGROUND})` }}
        >
          <div className="cws-overlay-bg"></div>
        </div>
        <div className="auto-container">
          <h1>Privacy Policy</h1>
        </div>
      </section>
      <section className="center-section">
        <div className="about-card">
          <p className="policy-text">
            By using our Services, you are agreeing to following Policy. We
            would like to tell you that if you don't agree to the Policy below,
            you can't use or access the Services in any manner.
            <br />
            <br />
            <span>
              <b>Effective date:</b> 28th November 2021
            </span>
            <br />
            <br />
            Please read on to learn the rules and restrictions that govern your
            use of our, products, services, mobile applications, website(s) and
            other software (collectively, the “Services”). If you have any
            questions, comments, or concerns regarding these Policy or our
            Services, please contact us at{" "}
            <a href="mailto:contact@technixia.com">contact@technixia.com.</a>
            <br />
            These Policy of Use (the “Policy”) are a binding contract between
            you and Technixia. You must agree to and accept all of the Policy,
            or you don't have the right to use the Services. Your use of the
            Services in any way means that you agree to all of these Policy, and
            these Policy will remain in effect while you use the Services. These
            Policy include the provisions in this document.
            <br />
            <h4>GENERAL</h4>
            <br />
            <ul>
              <li>
                This Mobile Application with the name of Technixia is operated
                by Technixia Automation Pvt Ltd. We are committed to protecting
                and respecting your privacy. We do collect your personal
                information and process your personal data in accordance with
                the IT Act, 2000 (21 of 2000) and other national and state laws
                which relate to the processing of personal data. Please read the
                following carefully to understand our views and practices
                regarding your personal data.
              </li>
              <li>
                Downloading, accessing, or otherwise using the App indicates
                that you have read this Privacy Policy and consent to its
                Policy. If you do not consent to the Policy of this Privacy
                Policy, do not proceed to download, access, or otherwise use the
                App.
              </li>
              <li>
                We collect your personal information in order to provide and
                continually improve our products and services.
              </li>
              <li>
                Our privacy policy is subject to change at any time without
                notice. To make sure you are aware of any changes, please review
                this policy periodically. The last updated date can be found at
                the beginning of this policy.
              </li>
            </ul>
            <h4> WILL THESE POLICY EVER CHANGE?</h4>
            <br />
            <ul>
              <li>
                We are constantly trying to improve our Services, so these
                Policy may need to change along with the Services. We reserve
                the right to change the Policy at any time, but if we do, we
                will bring it to your attention by placing a notice on the
                Services and/or by sending you an email.
              </li>
              <li>
                If you don't agree with the new Policy, you are free to reject
                them; unfortunately, that means you will no longer be able to
                use the Services. If you use the Services in any way after a
                change to the Policy is effective, that means you agree to all
                of the changes.
              </li>
              <li>
                Except for changes by us as described here, no other amendment
                or modification of these Policy will be effective unless in
                writing and signed by both you and us.
              </li>
            </ul>
            <h4> HOW WE COLLECT THE INFORMATION</h4>
            <br />
            <ul>
              <li>
                From you directly and through this App: We may collect
                information through the App when you visit. The data we collect
                depends on the context of your interactions with our App.
              </li>
              <li>
                Through business interaction: We may collect information through
                business interaction with you or your employees.
              </li>
              <li>
                From other sources: We may receive information from other
                sources, such as public databases; joint marketing partners;
                social media platforms; or other third parties such as
                Information about your interactions with the products and
                services offered by our subsidiaries.
              </li>
            </ul>
            <h4>INFORMATION WE COLLECT</h4>
            <br />
            We collect information primarily to provide better services to all
            of our customers. We collect the following information from you when
            you use or signup on our App:
            <ul>
              <br />
              <li>
                Name, Email, Phone Number (Optional), Gallery, Camera, Home
                Address, Wi-Fi Details, HUB ID (Technixia's Gateway ID which
                will be provided to you by Technixia)
              </li>
              <li>
                When you visit our App, some information is automatically
                collected. This may include information such as the type of
                mobile device, Operating System (OS) running on your device,
                Internet Protocol (IP) address, unique user ID, access times,
                device type, and language. We also collect information about how
                you use Our products or services.
              </li>
              <li>
                The information about your usage of the App, including crash
                logs and usage statistics.
              </li>
              <li>
                We automatically collect information using “Cookies”. Cookies
                are small data files stored on your device. Among other things,
                cookies help us to improve our App, our marketing activities,
                and your experience. We use cookies to see which areas and
                features are popular and to count visits to our App.
              </li>
              <li>
                We will retain your information as long as we require this to
                provide you with the goods and services and for such a period as
                mandated by the concerned laws.
              </li>
              <li>
                If you opt to receive marketing correspondence from us,
                subscribe to our mailing list or newsletters, enter into any of
                our competitions or provide us with your details at networking
                events, we may use your personal data for our legitimate
                interests in order to provide you with details about our goods,
                services, business updates and events.
              </li>
            </ul>
            <h4> HOW WE USE INFORMATION</h4>
            <br />
            We use the information we collect primarily to provide, maintain,
            protect and improve our current products and services. We use the
            information collected through this App as described in this policy
            and we may use your information to:
            <ul>
              <br />
              <li>
                Improve our services, App and how we operate Our businesses;
              </li>
              <li>
                Understand and enhance your experience using our App, products,
                and services;
              </li>
              <li>
                Personalize our products or services and make recommendations;
              </li>
              <li> Provide and deliver products and services you request,</li>
              <li> Process, manage, complete, and account for transactions;</li>
              <li>
                Provide customer support and respond to your requests, comments,
                and inquiries;
              </li>
              <li>
                Create and manage the online Technixia application-specific
                accounts you manage on our App;
              </li>
              <li>
                Send you related information, including confirmations, invoices,
                technical notices, updates, security alerts, and support and
                administrative messages;
              </li>
              <li>
                Communicate with you about promotions, upcoming events, and news
                about products and services;
              </li>
            </ul>
            <br />
            We may process your personal information without your knowledge or
            consent where required by applicable law or regulation for the
            purposes of verification of identity or for prevention, detection,
            or investigation, including of cyber incidents, prosecution, and
            punishment of offenses:
            <br />
            <br />
            <ul>
              <li>
                Protect, investigate and deter against fraudulent, unauthorized,
                or illegal activity.
              </li>
            </ul>
            <h4> DOES TECHNIXIA COST ANYTHING?</h4>
            <br />
            <ul>
              <li>
                Technixia will provide the control and monitor of all the
                devices manufactured by Technixia by a mobile application, free
                of cost for the life time. But Technixia reserves the right to
                require payment of fees for certain additional features of the
                Services. Should you elect to subscribe to such features, you
                shall pay all applicable fees, as described on the Services in
                connection with such features.
              </li>
              <li>
                Technixia reserves the right to change its price list and to
                institute new charges at any time, upon thirty (30) days prior
                notice to you, which may be sent by email or posted on the
                Services. Use of the Services by you following such notification
                constitutes your acceptance of any new or increased charges.
              </li>
            </ul>
            <h4> DATA TRANSFER</h4>
            <br />
            <ul>
              <li>
                Information about our users is an important part of our business
                and we take due care.
              </li>
              <li>
                We share your data with your consent or to complete any
                transaction or provide any product or service which is specific
                to our line of business to make your experience better which you
                have requested or authorized.
              </li>
              <li>
                We may employ other companies and individuals to perform
                functions on our behalf. The functions include fulfilling orders
                for products or services, delivering packages, sending postal
                mail and e-mail, removing repetitive information from customer
                lists, providing marketing assistance, providing search results
                and links (including paid listings and links), processing
                payments, transmitting content, scoring credit risk, and
                providing customer service
              </li>
              <li>
                These third-party service providers have access to personal
                information needed to perform their functions but may not use it
                for other purposes. Further, they must process the personal
                information in accordance with this Privacy Policy and as
                permitted by applicable data protection laws
              </li>
              <li>
                We release account and other personal information when we
                believe is appropriate to comply with the law, enforce or apply
                our conditions of use, and other agreements, protect the rights,
                property, or safety of Us, our users, or others. This includes
                exchanging information with other companies and organizations
                for fraud protection and credit risk reduction.
              </li>
            </ul>
            <h4>DATA SECURITY</h4>
            <br />
            <ul>
              <li>
                We take due care to protect our customer's data. Technical
                measures are in place to prevent unauthorized or unlawful access
                to data and against accidental loss or destruction of, or damage
                to the data. The employees who are dealing with the data have
                been trained to protect the data from any illegal or
                unauthorized usage.
              </li>
              <li>
                We maintain physical, electronic, and procedural safeguards in
                connection with the collection, storage, and disclosure of
                customers' personal information.
              </li>
              <li>
                We take reasonable steps to help protect your personal
                information in an effort to prevent loss, misuse, and
                unauthorized access, disclosure alteration, and destruction. It
                is your responsibility to protect your usernames and passwords
                to help prevent anyone from accessing or abusing your accounts
                and services.
              </li>
              <li>
                You should not use or reuse the same passwords you use with
                other accounts as your password for our services.
              </li>
              <li>
                It is important for you to protect against unauthorized access
                to your password and your devices, and applications. Be sure to
                sign off when you finish using a non-personal device.
              </li>
              <li>
                Information you provide to us is securely stored on our secured
                servers. We have implemented appropriate physical, technical and
                organizational measures designed to secure your information
                against accidental loss and unauthorized access, use,
                alteration, or disclosure. In addition, we limit access to
                personal data to those employees, agents, contractors, and other
                third parties that have a legitimate business need for such
                access.
              </li>
              <li>
                Information collected from you will be stored for such period as
                required to complete the transaction entered into with you or
                such period as mandated under the applicable laws.
              </li>
            </ul>
            <h4>LINKS TO THIRD PARTY SITE/APP</h4>
            <br />
            Our App may, from time to time, contain links to and from other
            App/Websites of third parties. Please note that if you follow a link
            to any of these App/Websites, such App/Websites will apply different
            Policy & Conditions specific to the data collection and privacy of
            your personal data and we do not accept any responsibility or
            liability for those sets of 3rd party interfaced policies. When you
            leave our Site, we encourage you to read the privacy policy of every
            App/Website you visit.
            <br />
            <br />
            For a better experience, while using our Service, we may require you
            to provide us with certain personally identifiable information. The
            information that we request will be retained by us and used as
            described in this privacy policy.
            <br />
            <br />
            The app does use third-party services that may collect information
            used to identify you. Link to the privacy policy of third-party
            service providers used by the app
            <br />
            <br />
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.google.com/policies/privacy/"
                >
                  Google Play Services
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://firebase.google.com/policies/analytics"
                >
                  Google Analytics for Firebase
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://firebase.google.com/support/privacy/"
                >
                  Firebase Crashlytics
                </a>
              </li>
            </ul>
            <h4> SHARING OF PERSONAL INFORMATION</h4>
            <br />
            We do not share your personal data with third parties without your
            prior consent other than:
            <ul>
              <br />
              <li>
                To comply with laws or to respond to lawful requests and legal
                process;
              </li>
              <li>
                To protect the rights and property of Us, our agents, customers,
                and others including to enforce our agreements, policies, and
                Policy of use:
              </li>
              <li>
                In an emergency, including to protect the personal safety of any
                person; and
              </li>
              <li>
                For the purpose of a business deal (or negotiation of a business
                deal) involving the sale or transfer of all or a part of our
                business or assets (business deals may include, for example, any
                merger, financing, acquisition, divestiture, or bankruptcy
                transaction or proceeding).
              </li>
            </ul>
            <h4>CHILDREN</h4>
            <br />
            If you are under the age of 18 or the age of majority in the
            jurisdiction in which you reside, you may only use Our App with the
            consent of your parent or legal guardian. In any case, we will not
            be liable for any cause of action that arose due to non-compliance
            with this section.
            <br />
            <h4>CHANGES TO THIS POLICY</h4>
            <br />
            We may change this policy from time to time. If we make any changes
            to this policy, we will change the “Last Updated” date above. You
            agree that your continued use of our services after such changes
            have been published to our services will constitute your acceptance
            of such revised policy. If you have any concerns about privacy or
            grievances with Us, please contact us with a thorough description
            and we will try to resolve the issue for you.
            <br />
            <h4> WHAT IF I WANT TO STOP USING THE SERVICES?</h4>
            <br />
            <ul>
              <li>
                You're free to do that at any time, by contacting us at
                contact@technixia.com; as well as the licenses above, to
                understand how we treat information you provide to us after you
                have requested deletion of your Services account. Technixia is
                also free to terminate (or suspend access to) your use of the
                Services or your account, for any reason in our discretion,
                including your breach of these Policy. Technixia has the sole
                right to decide whether you are in violation of any of the
                restrictions set forth in these Policy.
              </li>
              <li>
                Account termination may result in destruction of any User
                Submissions and/or Device Data associated with your account, so
                keep that in mind before you decide to terminate your account.
                We will try to provide advance notice to you prior to our
                terminating your account so that you are able to retrieve any
                important User Submissions or Device Data you may have stored in
                your account (to the extent allowed by law, these Policy, and
                the functionality of the Services), but we may not do so if we
                determine it would be impractical, illegal, not in the interest
                of someone's safety or security, or otherwise harmful to the
                rights or property of Technixia.
              </li>
              <li>
                If you have deleted your account by mistake, contact us
                immediately at contact@technixia.com - we will try to help, but
                unfortunately, we can't promise that we can recover or restore
                anything.
              </li>
              <li>
                Provisions that, by their nature, should survive termination of
                these Policy shall survive termination. By way of example, all
                of the following will survive termination: any obligation you
                have to pay us or indemnify us, any limitations on our
                liability, any Policy regarding ownership or intellectual
                property rights, and Policy regarding disputes between us.
              </li>
            </ul>
            <h4>WHAT ELSE DO I NEED TO KNOW</h4>
            <br />
            <ul>
              <li>
                <b>Indemnity.</b> You agree to indemnify and hold Technixia, its
                affiliates, officers, agents, employees, and partners harmless
                for and against any and all claims, liabilities, damages (actual
                and consequential), losses and expenses (including attorneys'
                fees) arising from or in any way related to any third-party
                claims relating to (a) your use of the Services (including any
                actions taken by a third party using your account), and (b) your
                violation of these Policy. In the event of such a claim, suit,
                or action (“Claim”), we will provide notice of the Claim to the
                contact information we have for your account (provided that
                failure to deliver such notice shall not eliminate or reduce
                your indemnification obligations hereunder).
              </li>
              <li>
                <b>Assignment.</b> You may not assign, delegate or transfer
                these Policy or your rights or obligations hereunder, or your
                Services account, in any way (by operation of law or otherwise)
                without Technixia's prior written consent. We may transfer,
                assign, or delegate these Policy and our rights and obligations
                without consent.
              </li>
              <li>
                <b>Choice of Law; Arbitration.</b> These Policy are governed by
                and will be construed under the laws of the State of West
                Bengal, without regard to the conflicts of law's provisions
                thereof. Any dispute arising from or relating to the subject
                matter of these Policy shall be finally settled in Kolkata, West
                Bengal.
              </li>
              <li>
                <b> Miscellaneous.</b> You will be responsible for withholding,
                filing, and reporting all taxes, duties, and other governmental
                assessments associated with your activity in connection with the
                Services. The failure of either you or us to exercise, in any
                way, any right herein shall not be deemed a waiver of any
                further rights hereunder. If any provision of this Agreement is
                found to be unenforceable or invalid, that provision will be
                limited or eliminated, to the minimum extent necessary, so that
                these Policy shall otherwise remain in full force and effect and
                enforceable. You and Technixia agree that these Policy are the
                complete and exclusive statement of the mutual understanding
                between you and Technixia, and that it supersedes and cancels
                all previous written and oral agreements, communications and
                other understandings relating to the subject matter of these
                Policy, and that all modifications to these Policy must be in a
                writing signed by both parties (except as otherwise provided
                herein). No agency, partnership, joint venture, or employment is
                created as a result of these Policy and you do not have any
                authority of any kind to bind Technixia in any respect
                whatsoever. You and Technixia agree there are no third-party
                beneficiaries intended under this Agreement.
              </li>
            </ul>
            <h4> Contact Details:</h4>
            <br />
            <b>Name:</b> Technixia Automation Pvt Ltd
            <br />
            <b>Email:</b> contact@technixia.com
            <br />
            <b>Address:</b> IIM Calcutta, Diamond Harbour Road, Joka, Kolkata,
            West Bengal 700104
            <br />
          </p>
        </div>
      </section>
      <Footer/>
    </div>
  );
};

export default PrivacyPolicy;
