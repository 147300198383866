import React from "react";
import Slider from "react-slick";
import "./courasal.css";
import { images } from "../../../utils/images";

const CustomizationCourasal = () => {
  const products = [
    {
      img: images.CUSTOMIZATION_OFFLINE,
      title: "Online + Offline Control",
      subheadline: "Technixia appliences works online (hub) & offline (Remote)",
    },
    {
      img: images.CUSTOMIZATION_MODULAR,
      title: "Select Modular Size",
      subheadline: "Our Switch boards are available in different modular sizes",
    },
    {
      img: images.CUSTOMIZATION_CHOOSE_SWITCH,
      title: "Choose Switches",
      subheadline: "Customize your switches as per your requirements",
    },
    {
      img: images.CUSTOMIZATION_LOCK,
      title: "5+ Access Digital Locks",
      subheadline: "More than 5 ways of Unlocking your Smart Door Lock",
    },
    {
      img: images.CUSTOMIZATION_INDOOR,
      title: "Inbuild Door Bell & Camera",
      subheadline:
        "Top mounted digital display for convenience with specially designed metal finish & curved body",
    },
    {
      img: images.CUSTOMIZATION_FRAME,
      title: "Select Frame",
      subheadline: "Choose color of Switch boards as per requirements",
    },
  ];

  const settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: false,
    autoplay: false,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
      <Slider {...settings} easing="">
        {products.map((item, index) => {
          return (
            <div className="content" key={index}>
              <div className="image-content">
                <img
                  className="img-self"
                  height={50}
                  width={50}
                  src={item.img}
                  style={{ objectFit: "contain" }}
                  alt="customization option"
                />
              </div>
              <div>
                <h5 className="center-text">{item.title}</h5>
                <p className="customization-center-text">{item.subheadline}</p>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default CustomizationCourasal;
