import React from "react";

const ProductSubcategoryCard = ({ item }) => {
  return (
    <div className="product-card-display col-lg-3 col-md-4 col-sm-12 animated fadeIn">
      <div className="product-display-image animated fadeIn">
        <img alt="product" src={item?.images[0]} className="product-display-image-img" />
        {/* <div className="product-card-button">
          <div className="prod-card-btn">View Products</div>
        </div> */}
      </div>
      <div className="prod-card-name">{item?.name}</div>
    </div>
  );
};

export default ProductSubcategoryCard;
