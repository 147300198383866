import React from "react";
import Home from "./pages/Home/Home";
import Page404 from "./pages/Page404";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import AllRoutes from "./components/Header/AllRoutes";
import Contact from "./pages/Contact/Contact";
import SmartTouchSwitchesCategory from "./pages/ProductCategories/SmartTouchSwitchesCategory";
import AboutUs from "./pages/AboutUs/AboutUs";
import ProductDetailedPage from "./pages/ProductDetailedPage/ProductDetailedPage";
import SmartSensorsCategory from "./pages/ProductCategories/SmartSensorsCategory";
import SmarDoorLocksCategory from "./pages/ProductCategories/SmarDoorLocksCategory";
import ScrollToTop from "./pages/ScrollToTop";
import PrivacyPolicy from "./pages/Policy/PrivacyPolicy";
import CustomBottomButton from "./components/CustomBottomButton";
import constants from "./utils/constants";
import { analyticsInstance } from "./globalFunctions/BaseTracking";

analyticsInstance().initialize([
  {
    trackingId: constants.trackingId,
    testMode: true,
  },
]);

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <CustomBottomButton />

      <Routes>
        <Route path={AllRoutes.HOME} element={<Home />} />
        <Route path="*" element={<Page404 />} />
        <Route path="" element={<Page404 />} />
        <Route
          path={AllRoutes.SMART_TOUCH_SWITCHES}
          element={<SmartTouchSwitchesCategory />}
        />
        <Route
          path={AllRoutes.SMART_SENSORS}
          element={<SmartSensorsCategory />}
        />
        <Route
          path={AllRoutes.SMART_DOOR_LOCKS}
          element={<SmarDoorLocksCategory />}
        />
        <Route path={AllRoutes.CONTACTS} element={<Contact />} />
        <Route
          path={AllRoutes.PRODUCT_DETAIL}
          element={<ProductDetailedPage />}
        />
        <Route path={AllRoutes.ABOUT} element={<AboutUs />} />
        <Route path={AllRoutes.PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
