import React, { useEffect, useState, useRef } from "react";
import SliderHome from "./Slider/SliderHome";
import ProductCategoriesHome from "./SubItems/ProductCategoriesHome";
import { useNavigate } from "react-router";
import BestSellerHome from "./SubItems/BestSellerHome";
import OurFootprintsSectionHome from "./SubItems/OurFootprintsSectionHome";
import WhyTechnixiaSectionHome from "./SubItems/WhyTechnixiaSectionHome";
import TestiMonial from "../../components/Testimonial/TestimonialGlobal";
import ApiCall from "../../api/ApiCall";
import { productRoutes } from "../../api/Routes";
import RecognisedBy from "../../components/RecognisedBy";
import Loader from "../../components/Loader";
import Footer from "../../components/Footer";
import BookDemoModal from "../../components/BookDemoModal";
import { getBannerData } from "../../globalFunctions/globalFunctions";
import { bannerKeys } from "../../utils/constants";

const Home = () => {
  const navigate = useNavigate();
  const [allBanner, setAllBanner] = useState(null);
  const [allProducts, setAllProducts] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [showingBookDemoModal, setShowingBookDemoModal] = useState(false);
  const ref = useRef(null);

  const scrollToElement = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToElement();
  }, []);

  useEffect(() => {    
    const handleGetAllProducts = async () => {
      const data = {
        isBestSeller: true,
        pageNumber: 1,
        pageLimit: 4,
      };
      const res = await ApiCall("post", productRoutes.getProducts, data);
      setAllProducts(res?.product?.data);
    };

    handleGetAllProducts();
    getBannerData(bannerKeys.HOME, setAllBanner);
  }, []);

  useEffect(() => {
    if (allBanner !== null && allProducts !== null) {
      setShowLoader(false);
    }
  }, [allBanner, allProducts]);

  return showLoader ? (
    <Loader />
  ) : (
    <div>
      <SliderHome navigate={navigate} allBanner={allBanner} />
      <BestSellerHome
        navigate={navigate}
        allProducts={allProducts}
        setAllProducts={setAllProducts}
      />
      <ProductCategoriesHome />
      <RecognisedBy text="RECOGNISED BY" />
      <TestiMonial ref={ref} />
      <OurFootprintsSectionHome />
      <WhyTechnixiaSectionHome
        onButtonPress={() => setShowingBookDemoModal(true)}
      />
      <Footer />
      <BookDemoModal
        setShowModal={setShowingBookDemoModal}
        showModal={showingBookDemoModal}
      />
    </div>
  );
};

export default Home;
