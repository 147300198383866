import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NavItem from "./Components/NavItem";
import {
  ContactRoutes,
  HomeRoutes,
  PortolioNavRoutes,
  AboutNavRoutes,
} from "./RouteUtils";
import AllRoutes from "./AllRoutes";
import { map } from "lodash";
import { images } from "../../utils/images";
import CustomButton from "../CustomButton";
import BookDemoModal from "../BookDemoModal";

const Header = ({ isDarkTheme = true }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [showStickyMenu, setShowStickyMenu] = useState(false);
  const [y, setY] = useState(window.scrollY);
  const [showModal, setShowModal] = useState(false);

  const CartItems = [
    {
      img: "https://www.technixia.com/wp-content/uploads/2022/08/Motion-e1660637159741.png",
      name: "Technixia T-Motion",
      price: "9999",
      quantity: 1,
    },
    {
      img: "https://www.technixia.com/wp-content/uploads/2022/08/Motion-e1660637159741.png",
      name: "Technixia T-Motion",
      price: "4999",
      quantity: 4,
    },
  ];

  const LogoImg = () => {
    return (
      <div className="logo">
        <Link to={AllRoutes.HOME}>
          <img
            src={images.TECHNIXIA_LOGO}
            alt="logo"
            className="main-header-logo-img"
          />
        </Link>
      </div>
    );
  };

  const MainMenu = ({
    additionalClass = "",
    isMobile = false,
    isSticky = false,
  }) => {
    return (
      <div
        className={`collapse navbar-collapse ${additionalClass}`}
        id="navbarSupportedContent"
      >
        <ul className="navigation clearfix">
          <NavItem
            isMobile={isMobile}
            isSticky={isSticky}
            routeType={AllRoutes.HOME}
            subMenu={HomeRoutes}
            isDarkTheme={isDarkTheme}
          />
          <NavItem
            isMobile={isMobile}
            isSticky={isSticky}
            routeType={AllRoutes.PORTFOLIO}
            subMenu={PortolioNavRoutes}
            isDarkTheme={isDarkTheme}
          />
          <NavItem
            isMobile={isMobile}
            isSticky={isSticky}
            routeType={AllRoutes.CONTACTS}
            subMenu={ContactRoutes}
            isDarkTheme={isDarkTheme}
          />
          <NavItem
            isMobile={isMobile}
            isSticky={isSticky}
            routeType={AllRoutes.ABOUT}
            subMenu={AboutNavRoutes}
            isDarkTheme={isDarkTheme}
          />
        </ul>
      </div>
    );
  };

  const SearchButton = ({ onpress = null }) => {
    return (
      <div className="search-box">
        <button
          className="search-btn"
          style={{
            marginTop: -5,
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 0,
            paddingLeft: 0,
          }}
          onClick={onpress}
        >
          <i
            style={{ color: isDarkTheme ? "#fff" : "#000" }}
            className="fa fa-search"
          ></i>
        </button>
      </div>
    );
  };

  const CartItem = ({ item }) => {
    return (
      <li className="cart-item">
        <img
          src={item?.img}
          alt="cart item"
          search-btn={true}
          className="thumb"
        />
        <span className="item-name">{item?.name}</span>
        <span className="item-quantity">
          {item?.quantity} x <span className="item-amount">₹{item?.price}</span>
        </span>
        <Link to={AllRoutes.ABOUT} className="product-detail">
          <button className="remove-item">
            <span className="fa fa-times"></span>
          </button>
        </Link>
      </li>
    );
  };

  const calculatedSubTotal = () => {
    let sum = 0;
    map(CartItems, (item) => {
      sum = sum + item?.quantity * parseInt(item?.price, 10);
    });
    return sum;
  };

  const CartButton = () => {
    return (
      <div
        className="cart-btn"
        style={{ marginTop: -5, marginLeft: 20, marginRight: 20 }}
      >
        <Link to={AllRoutes.CART}>
          <i className="fa fa-shopping-cart"></i>
          <span className="count">2</span>
        </Link>

        <div className="shopping-cart">
          <ul className="shopping-cart-items">
            {CartItems.map((item) => {
              return <CartItem item={item} />;
            })}
          </ul>
          <div className="cart-footer">
            <div className="shopping-cart-total">
              <strong>Subtotal:</strong> ₹{calculatedSubTotal()}
            </div>
            <Link to={AllRoutes.CART} className="theme-btn">
              View Cart
            </Link>
            <Link to={AllRoutes.CHECKOUT} className="theme-btn">
              Checkout
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
  };

  const MobileMenu = () => {
    return (
      <div className="mobile-menu">
        <nav className="menu-box">
          <div className="nav-logo">
            <Link to={AllRoutes.HOME}>
              <img
                src={images.TECHNIXIA_LOGO}
                alt="Sticky Logo"
                className="mobile-header-logo-img"
              />
            </Link>
          </div>
          <MainMenu additionalClass="show" isMobile={true} />
        </nav>
        <div className="close-btn" onClick={() => setShowMobileMenu(false)}>
          <span className="icon fa fa-times"></span>
        </div>
      </div>
    );
  };

  const MobileHeader = () => {
    return (
      <div className="mobile-header">
        <div className="logo">
          <Link to={AllRoutes.HOME}>
            <img
              src={images.TECHNIXIA_LOGO}
              alt="Sticky Logo"
              className="mobile-header-logo-img"
            />
          </Link>
        </div>

        {/* <!--Nav Box--> */}
        <div className="nav-outer clearfix">
          <div className="mobile-nav-toggler">
            <span
              style={{ color: isDarkTheme ? "#fff" : "#000" }}
              onClick={() => setShowMobileMenu(true)}
              className="icon fa fa-bars"
            ></span>
            {/* <SearchButton onpress={() => setShowSearch(true)} /> */}
            {/* <CartButton /> */}
          </div>
        </div>
      </div>
    );
  };

  const StickyHeader = () => {
    return (
      <div
        className="sticky-header"
        style={{
          position: "fixed",
          width: "100%",
          top: showStickyMenu ? 0 : -120,
        }}
      >
        <div className="outer-box">
          {/* <!--Logo--> */}
          <div className="logo">
            <Link to={AllRoutes.HOME} title="Sticky Logo">
              <img
                src={images.TECHNIXIA_LOGO}
                alt="Sticky Logo"
                className="sticky-header-logo-img"
              />
            </Link>
          </div>

          {/* <!--Nav Outer--> */}
          <div className="nav-outer">
            <nav className="main-menu">
              <MainMenu additionalClass="show" isSticky={true} />
            </nav>
            <FreeDemoButton />
          </div>
        </div>
      </div>
    );
  };

  const FreeDemoButton = () => {
    return (
      <CustomButton
        label="Book FREE Demo"
        customClass="header-button-demo"
        onPress={() => setShowModal(!showModal)}
      />
    );
  };

  const MainHeader = () => {
    return (
      <div className="main-box">
        <div className="menu-box">
          <LogoImg />
          <div className="nav-outer">
            <nav className="main-menu navbar-expand-md navbar-light">
              <MainMenu />
            </nav>
            <div className="outer-box clearfix">
              <FreeDemoButton />
              {/* <SearchButton onpress={() => setShowSearch(true)} />
              <CartButton /> */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      if (y > window.scrollY) {
        if (window.scrollY > 500) {
          setShowStickyMenu(true);
        } else {
          setShowStickyMenu(false);
        }
      } else if (y < window.scrollY) {
        setShowStickyMenu(false);
      }
      setY(window.scrollY);
    },
    [y]
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);
    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  return (
    <div>
      <header
        className={`main-header 
        ${showMobileMenu ? "mobile-menu-visible" : ""} 
        ${showStickyMenu ? "fixed-header" : ""} 
        ${showSearch ? "search-active" : ""}`}
      >
        <MainHeader />
        <StickyHeader />
        <MobileHeader />
        <MobileMenu />
        <div className="search-popup">
          <span className="search-back-drop"></span>
          <div className="search-inner">
            <button
              className="close-search"
              onClick={() => setShowSearch(false)}
            >
              <span className="fa fa-times"></span>
            </button>
            <form onSubmit={handleSearchSubmit}>
              <div className="form-group">
                <input
                  type="search"
                  name="search-field"
                  value={searchText}
                  placeholder="Search..."
                  autoComplete="off"
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
            </form>
          </div>
        </div>
      </header>
      <BookDemoModal setShowModal={setShowModal} showModal={showModal} />
    </div>
  );
};

export default Header;
