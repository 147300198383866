import React from "react";
import Header from "../../components/Header/Header";
import { images } from "../../utils/images";
import CustomH2Heading from "../../components/CustomH2Heading";
import { Link } from "react-router-dom";
import CourasalComponent from "../../components/Coursal/CourasalComponent";
import OverlayImageComponent from "../../components/OverlayImageComponent";
import {
  clienteleArr,
  coreValues,
  foundersArr,
  ourMissionVisionArr,
  teamMembers,
  aboutStrings,
  itemCourousalComponent,
} from "./AboutContent";
import FounderCard from "./FounderCard";
import AboutMissionCard from "./AboutMissionCard";
import CustomResponsiveImage from "../../components/CustomResponsiveImage";
import RecognisedBy from "../../components/RecognisedBy";
import Footer from "../../components/Footer";

const AboutUs = () => {
  return (
    <section>
      <section>
        <Header />
      </section>
      <div className="about-us">
        <div className="about-us-banner">
          <OverlayImageComponent
            image={images.ABOUT_BACKGROUND}
            alt="banner"
            className="about-us-banner-img"
          />
        </div>
      </div>
      <div className="about-container">
        <div className="about-card">
          <div className="about-logo-image">
            <img
              alt="logo"
              src={images.TECHNIXIA_LOGO}
              className="about-logo-image-img"
            />
          </div>
          <div className="about-heading">
            <CustomH2Heading
              title="What We Do"
              customClass="about-sub-heading"

              isUpperCase={false}
            />
            <p className="about-heading-description">
              {aboutStrings.WHAT_WE_DO}
            </p>
            <CustomH2Heading
              title="Why We Do"

              customClass="about-sub-heading"
              isUpperCase={false}
            />
            <p className="about-subheading-description">
              {aboutStrings.WHY_WE_DO}
            </p>
          </div>
        </div>
      </div>
      <div className="about-mission-team-container">
        <div className="about-mission-container row">
          {ourMissionVisionArr.map((item) => {
            return (
              <AboutMissionCard
                image={item?.image}
                description={item?.description}
                title={item?.title}
              />
            );
          })}
        </div>
        <div className="about-mission-founders">
          <div className="about-heading">
            <CustomH2Heading title={"Meet the founders"} isUpperCase />
          </div>
          {foundersArr.map((founder) => {
            return (
              <FounderCard
                image={founder?.image}
                name={founder?.name}
                position={founder?.position}
                isRight={founder?.isRight}
                linkedin={founder?.linkedin}
                about={founder?.about}
              />
            );
          })}
        </div>
      </div>
      <div className="our-team-details">
        <div className="about-heading">
          <CustomH2Heading title="meet our team" isUpperCase={true} />
        </div>
        <div className="our-team-container-w-100 justify-content-between">
          {teamMembers?.map((team) => {
            return (
              <div className="our-team-data">
                <div className="our-team-image">
                  <img
                    alt="team member"
                    src={team.image}
                    className="our-team-member-img"
                  />
                </div>
                <div className="team-member-container">
                  <p className="team-member-name">{team?.name}</p>

                  <span className="about-founders-card-top-linkedin">
                    <p className="team-member-designation">
                      {team?.designation}
                    </p>
                    <Link to={team?.linkedin} target="_blank">
                      <i
                        className="fa fa-linkedin-square linkedin-icon"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* <div className="about-our-journey">
        <div className="about-heading">
          <CustomH2Heading title="Our Journey" isUpperCase={true} />
        </div>
        <div className="about-journey-image">
          <CustomResponsiveImage
            imageArr={[
              {
                image: images.OUR_JOURNEY_MOBILE,
                maxWidth: 700,
              },
              {
                image: images.OUR_JOURNEY,
              },
            ]}
            className="about-journey-image-img"
            alt="Our journey"
          />
        </div>
      </div> */}
      <div className="about-our-values">
        <div className="about-heading">
          <CustomH2Heading
            title="Our Core Values"
            isUpperCase={false}

            customClass="about-sub-heading"
          />
        </div>
        <div className="footprint">
          <div className="footprint-items">
            {coreValues.map((item) => {
              return (
                <div className="footprint-item">
                  <img
                    className="footprint-image"
                    src={item?.image}
                    alt="footprint"
                  />
                  <p className="footprint-item-details">{item?.title}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <hr className="thin-green-line"></hr>
      <RecognisedBy text="RECOGNISED BY" className="about-recogonised-by" />
      <hr className="thin-green-line"></hr>
      <div className="about-recogonised-by">
        <div className="recognised-by-header">
          <CustomH2Heading
            customClass="recognised-by-header-heading"
            title={'OUR CLIENTELE'}
          />
        </div>
        <div className="recogonised-by-slideshow">
          <CourasalComponent
            list={clienteleArr}
            itemComponent={itemCourousalComponent}
          />
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default AboutUs;
