import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { analyticsInstance } from "../globalFunctions/BaseTracking";

export default function ScrollToTop() {
  const { pathname, search } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, search]);

  useEffect(() => {
    console.log("window", window.location.pathname);
    analyticsInstance().send({
      hitType: "pageview",
      page: window.location.pathname,
      title: window.location.pathname,
    });
  }, [pathname, search]);

  return null;
}
