import React, { useEffect, useState } from "react";
import TopSection from "./TopSection";
import ProductDescription from "./ProductDescription";
import ApiCall from "../../api/ApiCall";
import { productRoutes } from "../../api/Routes";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import AllRoutes from "../../components/Header/AllRoutes";
import Footer from "../../components/Footer";
import Loader from "../../components/Loader";
import { analyticsInstance } from "../../globalFunctions/BaseTracking";
import constants from "../../utils/constants";

const ProductDetailedPage = () => {
  const [productDetails, setproductDetails] = useState(null);
  const [searchParams] = useSearchParams({});
  const navigate = useNavigate();
  const id = searchParams.get("id");
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    if (id && id !== null) {
      const handleGetProductDetails = async () => {
        const data = {
          productId: id,
        };
        const res = await ApiCall(
          "post",
          productRoutes.getProductDetails,
          data
        );
        if (res?.product) {
          setproductDetails(res?.product);
        } else {
          navigate(AllRoutes.HOME);
        }
      };
      handleGetProductDetails();
    } else {
      navigate(AllRoutes.HOME);
    }
  }, [id, navigate]);

  useEffect(() => {
    if (productDetails !== null) {
      const productData = {
        productId: productDetails?._id,
        name: productDetails?.name,
        offer: productDetails?.offer,
        itemCode: productDetails?.itemCode,
        productCategoryId: productDetails?.category,
        productSubCategoryId: productDetails?.subCategory,
      };
      analyticsInstance().event({
        category: constants.PRODUCT,
        action: "product_view",
        label: "Product Viewed",
        options: productData,
      });
      setShowLoader(false);
    }
  }, [productDetails]);

  return showLoader ? (
    <Loader />
  ) : (
    <>
      <Header isDarkTheme={false} />
      <TopSection productDetails={productDetails} />
      <ProductDescription productDetails={productDetails} />
      <Footer />
    </>
  );
};

export default ProductDetailedPage;
