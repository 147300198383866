import React, { useEffect, useState } from "react";
import ProductCard from "../../components/ProductCard";
import ApiCall from "../../api/ApiCall";
import { productRoutes } from "../../api/Routes";

const ProductDescription = ({ productDetails }) => {
  const [relatedProducts, setRelatedProducts] = useState([]);
  let buttonTexts = ["DESCRIPTION", "REVIEWS"];
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    const handleGetAllProducts = async () => {
      const data = {
        productCategoryId: productDetails?.category,
        productSubCategoryId: productDetails?.subCategory,
        pageNumber: 1,
        pageLimit: 4,
      };
      const res = await ApiCall("post", productRoutes.getProducts, data);
      setRelatedProducts(res?.product?.data);
    };
    handleGetAllProducts();
  }, [productDetails]);

  return (
    <section>
      <div className="product-description-buttons">
        {buttonTexts.map((text, index) => {
          return (
            <button
              className="product-description-button"
              name={text}
              onClick={() => {
                setSelectedIndex(index);
              }}
              style={{
                backgroundColor: selectedIndex === index ? "white" : "#04592b",
                color: selectedIndex === index ? "#000" : "white",
              }}
            >
              {text}
            </button>
          );
        })}
      </div>
      {selectedIndex === 0 && (
        <div className="product-description-text-area">
          <h3 className="product-description-text-heading">
            {buttonTexts[selectedIndex]}
          </h3>
          <p className="product-description-text-description" type="text">
            {productDetails?.details}
          </p>
        </div>
      )}
      {selectedIndex === 1 && (
        <div className="product-description-text-area">
          <h3 className="product-description-text-heading">
            {buttonTexts[selectedIndex]}
          </h3>
          <p className="product-description-text-description" type="text">
            No Review Yet
          </p>
        </div>
      )}

      <div className="related-product-main">
        <h2 className="related-product-heading">Related Products</h2>
        <div
          className="related-products-container-w-100 d-flex prod-container-flex justify-content-start"
          style={{ padding: 0, marginTop: 20 }}
        >
          {relatedProducts?.map((product) => {
            return <ProductCard item={product} />;
          })}
        </div>
      </div>

      <div className="related-product-view-all-button-section">
        {/* <button className="related-product-view-all-button"> View All</button> */}
      </div>
    </section>
  );
};

export default ProductDescription;
