import React from "react";

const AboutMissionCard = ({ image, description, title }) => {
  return (
    <div className="about-mission-card col-sm-12 col-md-5 col-lg-5 ">
      <h4 className="about-mission-heading">{title}</h4>
      <img className="about-mission-img" src={image} alt={title} />
      <p className="about-mission-description">{description}</p>
    </div>
  );
};

export default AboutMissionCard;
