import ApiCall from "../api/ApiCall";
import { bannerRoutes, productRoutes } from "../api/Routes";

// Get Products

export const getBestSellerProducts = async (categoryId, setter) => {
  const data = {
    productCategoryId: categoryId,
    isBestSeller: true,
    pageNumber: 1,
    pageLimit: 4,
  };
  const res = await ApiCall("post", productRoutes.getProducts, data);
  setter(res?.product?.data);
};

export const getAllProducts = async (categoryId, setter, limit) => {
  const data = {
    productCategoryId: categoryId,
    pageNumber: 1,
    pageLimit: limit,
  };
  const res = await ApiCall("post", productRoutes.getProducts, data);
  setter(res?.product?.data);
};

export const getTrandingProducts = async (categoryId, setter) => {
  const data = {
    productCategoryId: categoryId,
    isLightningOffer: true,
    pageNumber: 1,
    pageLimit: 4,
  };
  const res = await ApiCall("post", productRoutes.getProducts, data);
  setter(res?.product?.data);
};

// Get Sub Categories

export const getproductSubCategories = async (categoryId, setter) => {
  const data = {
    productCategoryId: categoryId,
  };
  const res = await ApiCall("post", productRoutes.getProductSubCategory, data);
  setter(res?.productSubCategory);
};

// Get Banners

export const getBannerData = async(bannerType, setter) => {
  const res = await ApiCall("post", bannerRoutes.getTopBanner, {
    bannerPage: bannerType,
  });
  setter(res?.banner);
};
