const AllRoutes = {
  HOME: "/",
  ABOUT: "/about",
  CONTACTS: "/contact",
  CART: "/cart",
  INTERACTIVE_SECURITY: "/interactive-security",
  VIDEO_MONITORING: "/video-monitoring",
  ENERGY_MANAGEMENT: "/energy-management",
  SMART_TOUCH_SWITCHES: "/smart-touch-switches",
  SMART_SENSORS: "/smart-sensors",
  SMART_DOOR_LOCKS: "/smart-locks",
  INTELLEGENT_HOME: "/intellegent-home",
  PRODUCT_ROUTE: "/product",
  CHECKOUT: "/checkout",
  MY_ACCOUNT: "/my-account",
  PRODUCT_DETAIL: "/product-detail",
  PRIVACY_POLICY: "/privay-policy",
  TERMS_CONDITIONS: "/terms-conditions",
};

export default AllRoutes;
