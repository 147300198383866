import React from "react";

const CustomButton = ({
  label,
  onPress,
  customStyle = {},
  customClass = "",
}) => {
  return (
    <button
      onClick={onPress}
      className={`custom-button ${customClass}`}
      style={customStyle}
    >
      {label}
    </button>
  );
};

export default CustomButton;
