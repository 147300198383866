import React from "react";
import { images } from "../../../utils/images";
import CustomButton from "../../../components/CustomButton";
import CustomH2Heading from "../../../components/CustomH2Heading";

const WhyTechnixiaSectionHome = ({ onButtonPress }) => {
  const whyToByFromTechnixiaImages = [
    {
      image: images.WHYBUYFROMTECHNIXIA_1,
      text: "Exclusive Offer",
    },
    {
      image: images.WHYBUYFROMTECHNIXIA_2,
      text: "Free Installation",
    },
    {
      image: images.WHYBUYFROMTECHNIXIA_3,
      text: "Free Shipping",
    },
    {
      image: images.WHYBUYFROMTECHNIXIA_4,
      text: "No EMI Cost",
    },
    {
      image: images.WHYBUYFROMTECHNIXIA_5,
      text: "Secured Payment",
    },
  ];

  return (
    <section>
      <div className="why-technixia">
        <div className="why-technixia-item">
          <div className="why-technixia-item-1">
            <div className="why-technixia-details">
              <CustomH2Heading
                title="Why Technixia ?"
                customClass="why-technixia-details-heading"
              />
              <p className="why-technixia-details-details">
              We chose Technixia as our trusted partner for IoT automation solutions because they are pioneers in the field. With a team of dedicated technologists and hardware specialists, they consistently push the boundaries of innovation. Technixia's commitment to disruptive technologies, energy efficiency, and unique Building Management Systems sets them apart as leaders in the industry. Their seamless integration of voice control via Google Home and Amazon Alexa adds an extra layer of convenience and accessibility to their offerings. We believe that Technixia's expertise aligns perfectly with our vision for a smarter, more connected future
              </p>
              <div className="why-technixia-details-logos">
                <div className="why-technixia-details-logo">
                  <div
                    style={{
                      borderRadius: 999,
                      height: 70,
                      width: 70,
                      backgroundColor: "black",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
                    }}
                  >
                    <img
                      src={images.WHYTECHNIXIA_LOGO_1}
                      alt="why technixia"
                      style={{ height: 30, width: 30 }}
                    />
                  </div>
                  <p className="why-technixia-details-logo-detail">
                    Upgradable
                  </p>
                </div>
                <div className="why-technixia-details-logo">
                  <div
                    style={{
                      borderRadius: 999,
                      height: 70,
                      width: 70,
                      backgroundColor: "black",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
                    }}
                  >
                    <img
                      src={images.WHYTECHNIXIA_LOGO_2}
                      alt="why technixia"
                      style={{ height: 30, width: 30 }}
                    />
                  </div>
                  <p className="why-technixia-details-logo-detail">
                    Completely Retrofit
                  </p>
                </div>
                <div className="why-technixia-details-logo">
                  <div
                    style={{
                      borderRadius: 999,
                      height: 70,
                      width: 70,
                      backgroundColor: "black",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    <img
                      src={images.WHYTECHNIXIA_LOGO_3}
                      alt="why technixia"
                      style={{ height: 30, width: 30 }}
                    />
                  </div>
                  <p className="why-technixia-details-logo-detail">
                    End-To-End Secure
                  </p>
                </div>
                <div className="why-technixia-details-logo">
                  <div
                    style={{
                      borderRadius: 999,
                      height: 70,
                      width: 70,
                      backgroundColor: "black",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    <img
                      src={images.WHYTECHNIXIA_LOGO_4}
                      alt="why technixia"
                      style={{ height: 30, width: 30 }}
                    />
                  </div>
                  <p className="why-technixia-details-logo-detail">
                    Full stack solution
                  </p>
                </div>
              </div>
              <div className="why-by-from-technixia-button">
                <CustomButton label="CALL US FOR FREE DEMO" onPress={onButtonPress} />
              </div>
            </div>
          </div>
        </div>
        <div className="why-technixia-item">
          <div className="why-technixia-image">
            <img
              src={images.WHYTECHNIXIA_IMAGE}
              className="why-technixia-image"
              alt="why technixia"
            />
          </div>
        </div>
      </div>
      <div className="why-by-from-technixia">
        <div className="why-by-from-technixia-main">
          <div className="why-by-from-technixia-header">
            <CustomH2Heading
              title="Why to buy from TECHNIXIA directly"
              customClass="why-by-from-technixia-heading"
            />
          </div>
          <div className="why-by-from-technixia-image-section">
            {whyToByFromTechnixiaImages.map((image) => {
              return (
                <div className="why-by-from-technixia-image-item">
                  <img
                    src={image.image}
                    className="why-by-from-technixia-image"
                    alt="why technixia"
                  />
                  <p className="why-by-from-technixia-description">
                    {image.text}
                  </p>
                </div>
              );
            })}
          </div>
          <div className="why-by-from-technixia-button">
            <CustomButton label="Book a Free Demo" onPress={onButtonPress} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyTechnixiaSectionHome;
