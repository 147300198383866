import React, { useState } from "react";
import { icons, images } from "../../utils/images";
import Header from "../../components/Header/Header";
import constants from "../../utils/constants";
import ApiCall from "../../api/ApiCall";
import { customerRoutes } from "../../api/Routes";
import Footer from "../../components/Footer";

const Contact = () => {
  const [name, setname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const emailValidation =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const handleReset = () => {
    setname("");
    setPhoneNumber("");
    setEmail("");
    setMessage("");
  };

  const handleAddContact = async (e) => {
    e.preventDefault();
    const data = {
      userName: name,
      userNumber: phoneNumber,
      userEmail: email,
      message: message,
    };
    const res = await ApiCall("post", customerRoutes.addContact, data);
    if (res?.success) {
      handleReset();
      alert("Form Submitted Successfully");
    }
  };

  return (
    <div>
      <section>
        <Header isDarkTheme={true} />
      </section>
      <section className="page-title">
        <div
          className="cws-image-bg"
          style={{ backgroundImage: `url(${constants.HEADER_BACKGROUND})` }}
        >
          <div className="cws-overlay-bg"></div>
        </div>

        <div className="auto-container">
          <h1>Contact</h1>
        </div>
      </section>
      <div className="contact-section">
        <div className="contact-section-w-100 d-flex justify-content-center align-items-center">
          <div className="contact-icon">
            <div className="icon-details">
              <div className="contact-icon-box">
                <img
                  src={icons.PROJECTS_ICON}
                  alt="project icon"
                  className="contact-icon-box-img"
                />
              </div>
              <h4 className="icon-title">Your Project</h4>
            </div>
            <div className="icon-details">
              <div className="contact-icon-box">
                <img
                  src={icons.SUPPORT_ICON}
                  alt="project icon"
                  className="contact-icon-box-img"
                />
              </div>
              <h4 className="icon-title">Support</h4>
            </div>
            <div className="icon-details">
              <div className="contact-icon-box">
                <img
                  src={icons.PARTNER_ICON}
                  alt="project icon"
                  className="contact-icon-box-img"
                  style={{ width: "90px", height: "90px" }}
                />
              </div>
              <h4 className="icon-title">Become a partner</h4>
            </div>
          </div>
        </div>
      </div>
      {/* <div>
        <CustomButton
          label="Book Demo"
          onPress={() => {
            setShowModal(true);
          }}
        />
      </div> */}
      <div className="contact-map">
        <div className="w-100 d-flex">
          <div className="w-100 w-lg-50 p-0">
            <div className="contact-leftpart">
              <div className="contact-leftpart-image">
                <img
                  alt="logo"
                  src={images.CONTACT_IMAGE}
                  className="contact-leftpart-image-img"
                />
              </div>
            </div>
          </div>
          <div className="w-100 w-lg-50">
            <div className="contact-rightpart d-flex">
              <div className="heading">
                <h3 className="contact-rightpart-heading">How To Reach Us</h3>
                <div className="contact-info row">
                  <div className="contact-block col-lg-12 col-md-12 col-sm-12">
                    <div className="contact-block-inner">
                      <span className="icon fa fa-map-signs"></span>
                      <h5 className="contact-block-inner-heading">Address</h5>
                      <p className="contact-block-inner-description">
                        LB House, 162/A/107/1
                      </p>
                      <p className="contact-block-inner-description">
                        Lake Gardens, Kolkata - 700045
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-form">
        <div className="contact-form-w-100 d-flex">
          <div className="w-100 w-lg-50">
            <div className="about-contact">
              <div className="contact-heading d-flex justify-content-start align-items-start">
                Contact Us
              </div>
              <p className="contact-description d-flex justify-content-start align-items-start">
              We're here to assist you in every possible way. If you have any questions, inquiries, or feedback, please don't hesitate to reach out to us. Your thoughts and opinions are invaluable to us as we strive to improve and provide the best possible service.

Whether you're interested in our products, have a partnership proposal, or simply want to say hello, we're eager to hear from you. Our dedicated team is ready to respond to your queries promptly.
              </p>
            </div>
          </div>
          <div className="contact-form-w-100 w-lg-50">
            <div className="contact-inner-column">
              <div className="contact-main-form contact-form-two">
                <form onSubmit={handleAddContact}>
                  <div className="row mid-spacing">
                    <div className="form-group mb-0 col-lg-12">
                      <div className="response"></div>
                    </div>

                    <div className="form-group col-lg-6 col-md-6 col-sm-12">
                      <label>Your Name *</label>
                      <input
                        type="text"
                        name="username"
                        className="username"
                        required
                        placeholder="Enter Name"
                        value={name}
                        onChange={(event) => setname(event.target.value)}
                      />
                    </div>

                    <div className="form-group col-lg-6 col-md-6 col-sm-12">
                      <label>Email *</label>
                      <input
                        type="email"
                        name="email"
                        className="email"
                        required
                        placeholder="Enter Email"
                        pattern={emailValidation}
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                      />
                    </div>

                    <div className="form-group col-lg-12 col-md-12 col-sm-12">
                      <label>Phone *</label>
                      <input
                        type="text"
                        name="phone"
                        inputmode="numeric"
                        required
                        placeholder="Enter Phone Number"
                        value={phoneNumber}
                        maxlength="10"
                        minLength="10"
                        onChange={(e) => {
                          if (/^[0-9]*$/.test(e.target.value)) {
                            setPhoneNumber(e.target.value);
                          }
                        }}
                      />
                    </div>

                    <div className="form-group col-lg-12 col-md-12 col-sm-12">
                      <label>Message *</label>
                      <textarea
                        required
                        placeholder="Enter Message"
                        name="contact_message"
                        value={message}
                        onChange={(event) => setMessage(event.target.value)}
                      ></textarea>
                    </div>
                    <button type="submit" className="custom-button">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
