const constants = {
  HEADER_BACKGROUND: "https://i.imgur.com/DCdBXcq.jpg",
  FACEBOOK_LINK: "https://facebook.com/Technixia",
  TWITTER_LINK: "https://twitter.com/technixia",
  INSTAGRAM_LINK:
    "https://instagram.com/technixia_automation",
  WHATSAPP_LINK: "https://wa.me/9163436948",
  YOUTUBE_LINK: "https://www.youtube.com/@technixiaautomation",
  LINKEDIN_LINK: "https://www.linkedin.com/company/technixia/",
  TELEGRAM_LINK: "https://t.me/technixiasmart",
  GOOGLE_RATINGS_LINK: "https://g.page/r/CVrCZUeiGDX1EB0/review",

  trackingId: "UA-SFRW",
  PRODUCT: "product",
};

const bannerKeys = {
  SMART_SWITCHES: "smart_switches",
  SMART_LOCKS: "smart_locks",
  SMART_SENSORS: "smart_sensors",
  HOME: "home",
};

export default constants;
export { bannerKeys };
