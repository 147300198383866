import React, { useEffect, useState } from "react";

const CustomResponsiveImage = ({
  className = "",
  alt = "simple image",
  imageArr = [],
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const updateDimension = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [windowWidth]);

  for (let index = 0; index < imageArr.length; index++) {
    const { image, maxWidth = '' } = imageArr[index];
    if (windowWidth <= maxWidth || maxWidth.length === 0) {
      return <img className={className} alt={alt} src={image} />;
    }
  }
};

export default CustomResponsiveImage;
