import React from "react";
import Footer from "../components/Footer";

const Page404 = () => {
  return (
    <>
      <div>Page404</div>
      <Footer />
    </>
  );
};

export default Page404;
