import React from "react";
import { icons, images } from "../utils/images";
import { Link } from "react-router-dom";
import AllRoutes from "./Header/AllRoutes";
import constants from "../utils/constants";

const Footer = () => {
  const FooterLink = ({ title, to = "" }) => {
    return (
      <Link to={to}>
        <p>
          <span
            className="fa fa-caret-right"
            style={{ marginRight: 5 }}
            aria-hidden="true"
          ></span>
          &nbsp;{title}
        </p>
      </Link>
    );
  };

  const allIcons = [
    { icons: icons.FB_ICON, to: constants.FACEBOOK_LINK },
    { icons: icons.TWITTER_ICON, to: constants.TWITTER_LINK },
    { icons: icons.INSTAGRAM_ICON, to: constants.INSTAGRAM_LINK },
    { icons: icons.WHATSAPP_ICON, to: constants.WHATSAPP_LINK },
    { icons: icons.YOUTUBE_ICON, to: constants.YOUTUBE_LINK },
    { icons: icons.LINKEDIN_ICON, to: constants.LINKEDIN_LINK },
    { icons: icons.TELEGRAM_ICON, to: constants.TELEGRAM_LINK },
    { icons: icons.GOOGLE_BUSINESS_ICON, to: constants.GOOGLE_RATINGS_LINK },
  ];

  return (
    <section
      style={{
        backgroundImage: `url(${images.FOOTER_BACKGROUND})`,
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="footer-container">
        <div className="row footer-main-row">
          <div className="col-lg-3 col-md-6 col-sm-12 footer-row">
            <Link to={AllRoutes.HOME}>
              <img
                alt="logo"
                className="footer-logo"
                src={images.TECHNIXIA_LOGO}
              />
            </Link>
            <p className="mt-3">
              Technixia Automation Pvt.Ltd provides industrial, commercial and
              Home automation solutions with a goal is make people's life
              better, effortless & smarter
            </p>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 footer-row">
            <h5 className="mb-3">Quick Links</h5>
            <FooterLink
              title="Smart Switches"
              to={AllRoutes.SMART_TOUCH_SWITCHES}
            />
            <FooterLink title="Smart Locks" to={AllRoutes.SMART_DOOR_LOCKS} />
            <FooterLink title="Smart Sensors" to={AllRoutes.SMART_SENSORS} />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 footer-row">
            <h5 className="mb-3">SUPPORT</h5>
            <FooterLink title="About Us" to={AllRoutes.ABOUT} />
            <FooterLink title="Contact Us" to={AllRoutes.CONTACTS} />
            {/* <FooterLink title="Terms & Conditions" to={AllRoutes.TERMS_CONDITIONS} /> */}
            <FooterLink title="Privacy Policy" to={AllRoutes.PRIVACY_POLICY} />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 footer-row">
            <h5 className="mb-3">CONTACT US</h5>
            <div className="footer-icon-row">
              <img
                src={icons.LOCATION_ICON}
                className="footer-add-icons"
                alt="footer icon"
              />
              <p>LB House, 162/A/107/1, Lake Garden, Kolkata -700045</p>
            </div>
            <div className="footer-icon-row">
              <img
                src={icons.CALL_ICON}
                className="footer-add-icons"
                alt="footer icon"
              />
              <p>(+91) 9163436948</p>
            </div>
            <div className="footer-icon-row">
              <img
                src={icons.MAIL_ICON}
                className="footer-add-icons"
                alt="footer icon"
              />
              <p>contact@technixia.com</p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-icon-container">
        <div className="footer-social-icons">
          {allIcons?.map((icon) => {
            return (
              <Link to={icon.to} target="_blank" rel="noreferrer">
                <img
                  src={icon.icons}
                  alt="social icons"
                  className="footer-social-media-icons"
                />
              </Link>
            );
          })}
        </div>
      </div>
      <div className="footer-container-bottom">
        <p>2023,Technixia Automation Pvt. Ltd. All Rights Reserved.</p>
      </div>
    </section>
  );
};

export default Footer;
