import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import { images } from "../../utils/images";
import CustomH2Heading from "../../components/CustomH2Heading";
import BestSeller from "./BestSeller";
import TrendingSale from "./TrendingSale";
import AboutProduct from "./AboutProduct";
import TestiMonial from "../../components/Testimonial/TestimonialGlobal";
import AllProducts from "../../components/AllProducts";
import CustomResponsiveImage from "../../components/CustomResponsiveImage";
import {
  getAllProducts,
  getBestSellerProducts,
  getTrandingProducts,
} from "../../globalFunctions/globalFunctions";
import Footer from "../../components/Footer";
import Loader from "../../components/Loader";
import BookDemoModal from "../../components/BookDemoModal";
import { getBannerData } from "../../globalFunctions/globalFunctions";
import { bannerKeys } from "../../utils/constants";

const SmartSensorsCategory = () => {
  const categoryId = "6576b027392732f986eba230";
  const [showLoader, setShowLoader] = useState(true);
  const [showModalFreeDemo, setShowModalFreeDemo] = useState(false);
  const [allBanner, setAllBanner] = useState(null);

  const [trandingProducts, setTrandingProducts] = useState(null);
  const [allSensors, setAllSensors] = useState(null);
  const [bestSellerProducts, setBestSellerProducts] = useState(null);

  useEffect(() => {
    getTrandingProducts(categoryId, setTrandingProducts);
    getAllProducts(categoryId, setAllSensors, 100);
    getBestSellerProducts(categoryId, setBestSellerProducts);
    getBannerData(bannerKeys.SMART_SENSORS, setAllBanner);
  }, []);

  useEffect(() => {
    if (
      bestSellerProducts !== null &&
      trandingProducts !== null &&
      allSensors !== null &&
      allBanner !== null
    ) {
      setShowLoader(false);
    }
  }, [bestSellerProducts, trandingProducts, allSensors, allBanner]);

  const mainBanner = allBanner?.[0];
  const imagesArr = mainBanner?.backgroundImg;

  return showLoader ? (
    <Loader />
  ) : (
    <div>
      <section>
        <Header isDarkTheme={false} />
      </section>
      <div className="product-category-header">
        <div className="product-category-banner">
          <CustomResponsiveImage
            imageArr={imagesArr}
            className="prod-banner"
          />
        </div>
      </div>
      <div className="product-container">
        <div className="product-card-header-new">
          <CustomH2Heading
            title="Smart Sensors"
            isUpperCase={true}
            customClass="product-header"
          />
        </div>
        <div className="product-product-description">
          Experience the magic of automation with smart sensors - your home's
          invisible guardians!
        </div>
      </div>
      {allSensors && allSensors.length > 0 && (
        <AllProducts allSensorProducts={allSensors} />
      )}
      {bestSellerProducts && bestSellerProducts.length > 0 && (
        <BestSeller allProducts={bestSellerProducts} />
      )}
      {trandingProducts && trandingProducts.length > 0 && (
        <TrendingSale allProducts={trandingProducts} />
      )}
      <AboutProduct
        img={images.SMART_SENSOR}
        description={
          "Smart Sensors are intelligent devices equipped with motion detection capabilities and integrated communication technology, designed to detect movement in their surroundings and provide real-time data for various applications. These sensors are an essential part of smart home automation systems and other IoT applications, where they enable automated responses and enhance security and energy efficiency. Smart Sensors are versatile devices that offer significant benefits in terms of security, convenience, and energy efficiency. As part of a broader smart home or IoT ecosystem, they contribute to a more connected and automated environment, enhancing the overall user experience and enabling a safer and smarter way of living."
        }
        onButtonPress={() => setShowModalFreeDemo(true)}
      />
      <TestiMonial />
      <BookDemoModal
        setShowModal={setShowModalFreeDemo}
        showModal={showModalFreeDemo}
      />
      <Footer />
    </div>
  );
};

export default SmartSensorsCategory;
