import axios from "axios";
import { BASE_URL } from "./base";
import { decrypyData } from "./encryption";
import { resolveErrorMessage } from "./service";
// import { toast } from "react-toastify";

const API = axios.create({ baseURL: BASE_URL });

// API.interceptors.request.use((req) => {
//   if (localStorage.getItem("refreshToken")) {
//     req.headers.token = `${JSON.parse(localStorage.getItem("refreshToken"))}`;
//   }
//   req.headers.version = decrypyData(localStorage.getItem("version"));
//   req.headers.platform = "dashboard";
//   return req;
// });

const ApiCall = async (type, route, data) => {
  try {
    let response;
    if (type === "post") {
      response = await API.post(route, data);
    } else {
      response = await API.get(route, data);
    }

    var success = true;
    if (response?.data?.success) {
      return response?.data;
    }
  } catch (error) {
    success = false;
    // toast.error(
    //   `Something went wrong, ${resolveErrorMessage(error)},${
    //     error?.response?.status
    //   }`
    // );
    return {
      success,
      error: resolveErrorMessage(error),
      status: error?.response?.status,
    };
  }
};

export default ApiCall;


// ********* EXAMPLE OF API CALL ********
// const handleAddProduct = async () => {
//     const res = await ApiCall(
//       "post", //API TYPE
//       productRoutes.addProduct, //API ROUTE
//       sendData  //API BODY
//     );
//     if (res?.success) {
//       //IF PART (STOP LOADER & SET SUCCESS MESSAGE)
//     } else {
//       seterrorMessage(res.error);  //ELSE PART (STOP LOADER & SET ERROR MESSAGE LIKE THIS)
//     }
  
// };