import React from "react";
import { images } from "../utils/images";

const Loader = () => {
  return (
    <div className="body-loader">
      <img src={images.LOGO_LOADER} alt="loader" />
      <h1 className="ml6">
        <span className="text-wrapper">
          <span className="letters">Think Smart</span>
        </span>
      </h1>
    </div>
  );
};

export default Loader;
