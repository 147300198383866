const productPrefix = "product";
const bannerPrefix = "topbanner";
const customerPrefix = "customer"

// ***** PRODUCT RELATED ROUTES *****
export const productRoutes = {
  addProduct: productPrefix + "/add-product",
  editProduct: productPrefix + "/edit-product",
  getProducts: productPrefix + "/get-products",
  getProductSubCategory: productPrefix + "/get-product-subcategory",
  getProductDetails: productPrefix + "/get-product-details",
};

// ***** BANNER RELATED ROUTES  *****
export const bannerRoutes = {
  getTopBanner: bannerPrefix + "/get-topbanner"
}

// ***** REVIEW RELATED ROUTES  *****
export const customerRoutes = {
  getCustomerReview: customerPrefix + "/get-customer-review",
  addContact: customerPrefix + "/add-contact",
  addCustomerRequest: customerPrefix + "/add-customer-request",
  addFreeDemoRequest: customerPrefix + "/add-freedemo",
}