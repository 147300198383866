import React from "react";
import CustomButton from "../../components/CustomButton";

const AboutProduct = ({ img, description ,onButtonPress}) => {
  return (
    <section>
      <div className="about-product">
        <div className="about-product-container-w-100 d-flex">
          <div className="about-product-w-25">
            <div className="product-display-image-about">
              <img
                alt="product"
                src={img}
                className="product-display-image-img"
              />
            </div>
          </div>
          <div className="prod-desc-container">
            <p className="prod-desc-description">{description}</p>
            <div className="prod-desc-button">
              <CustomButton label="Book a FREE demo" onPress={onButtonPress} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutProduct;
