import React from "react";
import { Link } from "react-router-dom";
import AllRoutes from "./Header/AllRoutes";
import Countdown, { zeroPad } from "react-countdown";
import { productRoutes } from "../api/Routes";
import ApiCall from "../api/ApiCall";
import { getBestSellerProducts } from "../globalFunctions/globalFunctions";

const ProductCard = ({ item, allProducts, setAllProducts }) => {
  const getSecondsBetweenDates = (date) => {
    const now = new Date();
    const futureDate = new Date(date);
    return Math.max(0, Math.floor(futureDate - now));
  };
  const editProduct = async (itemId) => {
    const sendData = {
      productId: itemId,
      productCategoryId: item?.category,
      productSubCategoryId: item?.subCategory,
      images: item?.images,
      name: item?.name,
      details: item?.details,
      basePrice: item?.basePrice,
      offer: item?.offer,
      leastPrice: item?.leastPrice,
      isLightningOffer: false,
      startAt: "",
      expireAt: "",
    };
    await ApiCall("post", productRoutes.editProduct, sendData);
    getBestSellerProducts(item?.category, setAllProducts);
  };

  const isLightiningSale = item?.lightningOffer?.isLightningOffer || false;

  const countDownRender = ({ days, hours, minutes, seconds }) => (
    <span className="countdown-timer-text">
      {zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
    </span>
  );

  return (
    <>
      <div className="product-data animated fadeIn">
        <>
          <div className="product-card-image">
            <img
              alt="details"
              src={item?.images?.[0]}
              className="product-card-image-img"
            />
            {/* {isLightiningSale && (
              <div className="upper-button">
                <i className="fa fa-bolt" aria-hidden="true"></i>&nbsp;
              <Countdown
                  date={
                    Date.now() +
                    getSecondsBetweenDates(item?.lightningOffer?.expireAt)
                  }
                  onComplete={() => {
                    editProduct(item._id);
                  }}
                  renderer={countDownRender}
                >
                  <span>time expired</span>
                </Countdown>
              </div>
            )} */}
            {/* <div className="product-display-icon">
          <i className="fa fa-heart-o" aria-hidden="true"></i>
        </div> */}
          </div>
          <div className="prod-name">
            <p className="product-name">
              {item?.name} {item?.itemCode ? `(${item?.itemCode})` : ""}
            </p>
            {/* {isLightiningSale && (
              <div className="product-actual-price-container">
                <p className="product-actual-price">
                  Rs {item?.basePrice} INR /-
                </p>
                <p className="offer-tag">{item?.offer}% 0ff</p>
              </div>
            )}
            <p className="product-offer-price">
              Rs {isLightiningSale ? item?.leastPrice : item?.basePrice} INR /-
            </p> */}

            {!isLightiningSale && (
              <div className="prod-rating" style={{ fontSize: 16 }}>
                <span className="prod-rating-icon">
                  <i className="fa fa-star" aria-hidden="true"></i>
                </span>
                <span className="prod-rating-icon">
                  <i className="fa fa-star" aria-hidden="true"></i>
                </span>
                <span className="prod-rating-icon">
                  <i className="fa fa-star" aria-hidden="true"></i>
                </span>
                <span className="prod-rating-icon">
                  <i className="fa fa-star" aria-hidden="true"></i>
                </span>
                <span className="prod-rating-icon">
                  <i className="fa fa-star" aria-hidden="true"></i>
                </span>
              </div>
            )}
          </div>
        </>
        <Link to={AllRoutes.PRODUCT_DETAIL + `?id=${item?._id}`}>
          <div className="button-container">
            <p className="prod-details-button">
              <i className="fa fa-shopping-cart" aria-hidden="true"></i>
              View Product
            </p>
          </div>
        </Link>
      </div>
    </>
  );
};

export default ProductCard;
