import React from "react";

const CustomH2Heading = ({
  title,
  customClass = "",
  isUpperCase = false,
}) => {
  return (
    <>
      <div className={`text-center`}>
        <h2
          className={`${
            isUpperCase ? "text-uppercase" : ""
          } fw-bolder common-h2-heading ${customClass}`}
        >
          {title}
        </h2>
      </div>
      <div className="about-mission-founder-hr">
        <div className="about-mission-founder-hr-line"></div>
      </div>
    </>
  );
};

export default CustomH2Heading;
